import React from 'react';

export default function ClearModal() {
  return (
    <div
      class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="clear-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5
              class="text-xl font-medium leading-normal text-gray-800"
              id="exampleModalLabel"
            >
              Clear Sketch
            </h5>
            <button
              type="button"
              class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              data-dismiss="modal"
            />
          </div>

          <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button
              type="button"
              class={`px-6
          py-2.5
          bg-gray-500
          text-white
          font-medium
          text-xs
          leading-tight
          uppercase
          rounded
          shadow-md
         
          transition
          duration-150
          ease-in-out`}
              data-bs-dismiss="modal"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              id="clear-sketch"
              type="button"
              class={`px-6
          py-2.5
          !bg-header
          text-white
          font-medium
          text-xs
          leading-tight
          uppercase
          rounded
          shadow-md
         
          transition
          duration-150
          ease-in-out`}
              data-bs-dismiss="modal"
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
