import { Tool } from "./Tool.js";

export class TextTool extends Tool {
  constructor(canvas, preview_canvas, options) {
    super(canvas, preview_canvas, options);
    this.startPosition = null;
    this.fontSize = options.fontSize;
    //this.textInput = options.textInput;
    this.textInput = $("#hidden-input");
  }

  activate(options) {
    super.activate(options);
    this.string = "";
    this.layer = null;
    this.show_input();
    this.createEventListeners();
  }

  show_input() {
    this.textInput.removeClass("hidden");
    this.textInput.val("");
  }

  hide_input() {
    this.textInput.addClass("hidden");
    this.textInput.val("");
  }

  updateFontSize(fontSize) {
    this.fontSize = fontSize;
    let textLayers = this.canvas.getLayers(function (layer) {
      return layer.type == "text";
    });
    for (var i = textLayers.length - 1; i >= 0; i--) {
      let layerName = textLayers[i].name;
      textLayers[i].fontSize = fontSize;
      this.canvas.setLayer(layerName, textLayers[i]);
    }
    this.draw(this.canvas);
  }

  createHandlers() {
    var tool = this;
    var getPosition = this.options.getPosition;

    this.clickHandler = (function () {
      return function (e) {
        e.preventDefault();

        let textLayers = tool.canvas.getLayers(function (layer) {
          return layer.type == "text";
        });
        console.log(textLayers[0]);

        for (var i = textLayers.length - 1; i >= 0; i--) {
          let layer = textLayers[i];
          console.log(layer.dragging);
          console.log(layer.intersects);
          if (layer.text === "|") {
            tool.canvas.removeLayerGroup(layer.name);
          }
          if (layer.intersects) return true;
        }

        console.log(e);

        let position = getPosition(e);

        tool.startStringCapture(position);
        return true;
      };
    })();

    this.keystrokeHandler = (function () {
      return function (e) {
        e.preventDefault();
        tool.addToString(e);
        return true;
      };
    })();
  }

  createEventListeners() {
    this.canvas[0].addEventListener("touchstart", this.clickHandler, false);
    this.canvas[0].addEventListener("click", this.clickHandler, false);
    window.addEventListener("keydown", this.keystrokeHandler, false);

    return true;
  }

  destroyEventListeners() {
    this.canvas[0].removeEventListener("touchstart", this.clickHandler, false);
    this.canvas[0].removeEventListener("click", this.clickHandler, false);
    window.removeEventListener("keydown", this.keystrokeHandler, false);
    return true;
  }

  close() {
    console.log("close");
    super.close();
    this.destroyEventListeners();
    if (this.layer) {
      this.editTool.add_object(this.layer);
    }

    let textLayers = this.canvas.getLayers(function (layer) {
      return layer.type == "text";
    });
    for (var i = textLayers.length - 1; i >= 0; i--) {
      let layer = textLayers[i];
      console.log(layer);
      if (layer.text === "|") {
        this.canvas.removeLayerGroup(layer.name);
      }
    }

    this.hide_input();
  }

  startStringCapture(position) {
    this.string = {
      canvas: this.canvas,
      background: "none",
      text: "|",
      x: position.x,
      y: position.y,
      groups: ["notes"],
      type: "text",
      rotate: 0,
    };

    let text = this.addText(this.string);

    this.string.name = text.name;
    this.textInput.focus();
    //this.canvas.removeLayerGroup(this.layer.name);
    this.draw(this.canvas);
    return true;
  }

  addToString(keystroke) {
    //if (this.string.text === "|") this.string.text = "";
    let new_string_value = "";
    switch (keystroke.keyCode) {
      case 8:
        new_string_value = this.string.text.substring(
          0,
          this.string.text.length - 1
        );
        break;

      case 9:
        new_string_value = this.string.text;
        break;

      case 13:
        new_string_value = this.string.text + "\n";
        break;

      case 16:
        new_string_value = this.string.text;
        break;

      case 17:
        new_string_value = this.string.text;
        break;

      case 18:
        new_string_value = this.string.text;
        break;

      case 20:
        new_string_value = this.string.text;
        break;

      case 27:
        new_string_value = this.string.text;
        break;

      case 91:
        new_string_value = this.string.text;
        break;

      case 93:
        new_string_value = this.string.text;
        break;

      default:
        new_string_value = this.string.text + keystroke.key;
    }
    new_string_value = new_string_value.replace("|", "");
    this.string.text = new_string_value;
    this.textInput.val(this.string.text);
    let text = this.addText(this.string);

    this.layer = text;

    this.string.name = text.name;
    this.draw(this.canvas);
    return true;
  }

  createText(options) {
    let layer = this.addText(options);
    this.layer = layer;

    this.close();
  }

  highlight(name) {
    let layer = this.canvas.getLayer(name);
    this.canvas.removeLayerGroup("highlight");
    let text = {
      name: "highlight",
      type: "text",
      strokeStyle: "rgba(0,0,0,1)",
      strokeWidth: 1,
      shadowBlur: 4,
      shadowColor: "rgba(243,243,21,1)",
      index: 999,
      fontSize: this.fontSize,
      fontFamily: "Verdana, sans-serif",
      text: layer.text,
      x: layer.x,
      y: layer.y,
      groups: ["highlight"],
      draggable: false,
      rotate: layer.rotate,
    };
    this.canvas.addLayer(text);
    this.draw(this.canvas);
  }

  addText(options) {
    //console.log(this.string.text);
    //if (this.string.text === "|") this.string.text = "";

    this.removeText(options);
    let canvas = options.canvas;
    let name = options.name || this.uuid();

    let text;

    if (!options.groups.includes(name)) {
      options.groups.push(name);
    }

    if (options.background == "none") {
      if (!options.groups.includes("text-manual")) {
        options.groups.push("text-manual");
      }
      text = {
        name: name,
        type: "text",
        strokeStyle: "rgba(0, 0, 0, 1)",
        fillStyle: "rgba(0, 0, 0, 1)",
        strokeWidth: 1,
        x: options.x,
        y: options.y,
        fontSize: this.fontSize,
        fontFamily: "Verdana, sans-serif",
        text: options.text,
        groups: options.groups,
        dragGroups: options.dragGroups,
        draggable: true,
        drag: function (layer) {
          layer.data.drawParams.options.x = layer.x;
          layer.data.drawParams.options.y = layer.y;
        },
        dragstop: function (layer) {
          layer.data.drawParams.options.x = layer.x;
          layer.data.drawParams.options.y = layer.y;
        },
        rotate: options.angle,
        data: {
          friendly_name: `"${options.text}"`,
          type: "text",
          drawParams: {
            type: "text",
            options: {
              background: options.background,
              groups: options.groups,
              name: options.name,
              text: options.text,
              angle: options.angle,
              x: options.x,
              y: options.y,
              drawMeasurements: options.drawMeasurements,
            },
          },
        },
      };
      canvas.addLayer(text);
    } else {
      options.groups.push("text");
      if (options.text !== "0ft") {
        text = {
          name: name,
          type: "text",
          strokeStyle: "rgba(0, 0, 0, 1)",
          fillStyle: "rgba(0, 0, 0, 1)",
          strokeWidth: 1,
          x: options.x,
          y: options.y,
          fontSize: this.fontSize,
          fontFamily: "Verdana, sans-serif",
          text: options.text,
          groups: options.groups,
          dragGroups: options.dragGroups,
          draggable: false,
          rotate: options.angle,
          visible: options.visible,
          data: {
            friendly_name: `"${options.text}"`,
            type: "text",
            drawParams: {
              type: "text",
              options: {
                background: options.background,
                groups: options.groups,
                name: options.name,
                text: options.text,
                x: options.x,
                y: options.y,
                drawMeasurements: options.drawMeasurements,
              },
            },
          },
        };
        canvas.addLayer(text);
      }
    }

    return text;
  }

  removeText(options) {
    let canvas = options.canvas;

    if (options.name) {
      canvas.removeLayer(options.name);
    }
  }
}
