export class SelectorTool{
	constructor(canvas, preview_canvas, options){
		this.canvas = canvas;
		this.preview_canvas = preview_canvas;
		this.active = false;
	}

	set active(active){ this._active = active }
	get active(){ return this._active }

	set canvas(canvas){ this._canvas = canvas }
	get canvas(){ return this._canvas }

	set preview_canvas(preview_canvas){ this._preview_canvas = preview_canvas }
	get preview_canvas(){ return this._preview_canvas }

	activate(options){
		this.active = true;
		this.createEventListeners();
		this.canvas.setLayers({ draggable: false });
		this.canvas.setLayerGroup('handles',{ draggable: true });
		this.canvas.setLayerGroup('text-manual',{ draggable: true });
	}

	onMouseDown(position){ return true }
	onMouseUp(position){ return true }
	onMouseLeave(position){	return true }
	onClick(position){ return true }
	onMouseMove(position){ return true }
	onKeystroke(keystroke){ return true	}

	close(){
		this.active = false;
		this.canvas.setLayers({ draggable: false });
		this.destroyEventListeners();
	}

	createEventListeners(){
		return true;
	}

	destroyEventListeners(){
		return true;
	}
}