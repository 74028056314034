import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { login, signInWithGoogle, auth } from ".././api/auth";
import { useAuthState } from "react-firebase-hooks/auth";
//import '../styles/Login.css';
import { notify } from ".././utils/notification";

function Login(props) {
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/");
  }, [user, loading]);

  const onEnterKey = async (event) => {
    if (event.charCode === 13) {
      let email = emailRef.current.value;
      let password = passwordRef.current.value;

      if (!email || !password)
        return notify(
          "danger",
          "Missing Fields",
          "Please fill out all required fields."
        );

      const response = await login(email, password);
      console.log(response);
      if (response.error) {
        return notify("danger", "Login Failed", response.error);
      }

      //localStorage.setItem('user', JSON.stringify(response.success.user));
      //dispatch({ type: 'LOGIN', data: response.success.user});
      //props.login(response.success.user);
      return;
    }
  };

  return (
    <div className='relative font-sans text-primaryText overflow-hidden w-full h-full'>
      <div className='m-auto w-3/4 max-w-sm h-2/5 rounded-md px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
        <div className='grid grid-cols-1 space-y-5 w-full ml-auto mr-auto m-auto px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
          <img
            className='inline ml-auto mr-auto w-24'
            src={require("../config.json").logo}
          />
          <input
            ref={emailRef}
            placeholder='Email'
            spellcheck='false'
            id='newIp'
            onKeyPress={onEnterKey}
            autoFocus
            className='pt-2 rounded-md  text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full'
            //onChange={(e)=> setEmail(emailRef.current.value)}
          />
          <input
            ref={passwordRef}
            type='password'
            placeholder='Password'
            id='newIp'
            onKeyPress={onEnterKey}
            className='rounded-md  text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full'
            //onChange={(e)=> setPassword(passwordRef.current.value)}
          />

          <button
            onClick={() => onEnterKey({ charCode: 13 })}
            // onClick={() =>
            //   loginWithEmailAndPassword(
            //     emailRef.current.value,
            //     passwordRef.current.value
            //   )
            // }
            //onClick={signInWithGoogle}
            className='font-medium h-[48px] p-1 text-white bg-header shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300'
          >
            Login
          </button>
          <div class='relative flex py-3 items-center'>
            <div class='flex-grow border-t border-gray-400'></div>
            <span class='flex-shrink mx-4 text-gray-400'>OR</span>
            <div class='flex-grow border-t border-gray-400'></div>
          </div>
          <button
            onClick={signInWithGoogle}
            className='flex justify-evenly bg-white items-center font-medium p-1 text-[#7B7B7B] bg-none shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300'
          >
            <img
              className='inline-block w-10 p-1'
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png'
              alt='google icon'
            />
            Sign in with Google
          </button>
          <div className='grid-rows-1 flex flex-wrap content-evenly'>
            <button
              onClick={() => navigate("/register")}
              className='focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto'
            >
              <text class='buttonText text-xs opacity-60'>Create account</text>
            </button>
            <button
              onClick={() => navigate("/reset-password")}
              className='focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto'
            >
              <text class='buttonText text-xs opacity-60'>
                Forgot password?
              </text>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
