import React, { createContext, useContext, useState, useEffect, useReducer } from 'react';

export const AuthContext = createContext();

// Initial state
const INITIAL_STATE = {
  user: null
};

// Actions
export const SET_USER = 'SET_USER';

// Action creators
export function setUser(data) {
  return { type: SET_USER, data: data };
}

// Reducer
export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER:
      console.log(action.data);
      return { ...state, user: action.data };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [auth, dispatch] = useReducer(authReducer, INITIAL_STATE);

  const authData = { auth, dispatch };

  return (
    <AuthContext.Provider
      value={authData}
      {...props}
    />
  );
}

function useAuthContext() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuthContext };
