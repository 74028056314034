export class ScrollTool {
	constructor(canvas, preview_canvas){
		this.canvas = canvas;
		this.preview_canvas = preview_canvas;
		this.active = false;
	}

	activate(options){
		this.active = true;
		this.canvas.setLayers({'disableEvents': true});
		this.preview_canvas.setLayers({'disableEvents': true});
	}

	close(){
		this.active = false;
		this.canvas.setLayers({'disableEvents': false});
		this.preview_canvas.setLayers({'disableEvents': false});
	}
}