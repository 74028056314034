/*
GRID CLASS
This class handles drawing a grid of arbitrary width and height as an SVG onto a canvas. 
Because jCanvas only has options to re-draw the entire canvas, this should be drawn on
a secondary canvas and then drawn to the primary canvas only when saving.
*/
export class GridTool {
    constructor(drawing_canvas, preview_canvas, grid_canvas, save_canvas, draw_space, options) {
        this.name = 'Grid';
        this.drawing_canvas = drawing_canvas;
        this.preview_canvas = preview_canvas;
        this.grid_canvas = grid_canvas;
        this.save_canvas = save_canvas;
        this.draw_space = draw_space;

        this.width = options.width || grid_canvas.width;
        this.height = options.height || grid_canvas.width;
        this.scale = options.scale || 1
        this.gridSizePts = options.gridSizePts || 12;
        this.pt_per_ft = options.pt_per_ft || 6;
        this.snapToGrid = options.snapToGrid !== false;
        this.snapUnit = options.snapUnit || 6;
        this.handles = null;
    }

    set name(name) { this._name = name }
    get name() { return this._name }

    set width(width) { this._width = width }
    get width() { return this._width }

    set height(height) { this._height = height }
    get height() { return this._height }

    set gridSizePts(gridSizePts) { this._gridSizePts = gridSizePts }
    get gridSizePts() { return this._gridSizePts }

    set pt_per_ft(pt_per_ft) { this._pt_per_ft = pt_per_ft }
    get pt_per_ft() { return this._pt_per_ft }

    set drawing_canvas(drawing_canvas) { this._drawing_canvas = drawing_canvas }
    get drawing_canvas() { return this._drawing_canvas }

    set grid_canvas(grid_canvas) { this._grid_canvas = grid_canvas }
    get grid_canvas() { return this._grid_canvas }

    set save_canvas(save_canvas) { this._save_canvas = save_canvas }
    get save_canvas() { return this._save_canvas }

    set preview_canvas(preview_canvas) { this._preview_canvas = preview_canvas }
    get preview_canvas() { return this._preview_canvas }

    set snapToGrid(snapToGrid) { this._snapToGrid = snapToGrid }
    get snapToGrid() { return this._snapToGrid }

    get gridSize() { return this.width }

    adjustScale(new_scale) {
        this.drawing_canvas.scaleCanvas({ scale: 1 / this.scale }).scaleCanvas({ scale: new_scale });
        this.preview_canvas.scaleCanvas({ scale: 1 / this.scale }).scaleCanvas({ scale: new_scale });
        this.scale = new_scale;

        this.draw(this.drawing_canvas);
        this.draw(this.preview_canvas);
        this.createSVG();
    }

    getHandles() {
        this.handles = this.drawing_canvas.getLayerGroup('handles');
    }

    updateGridSize(gridSize) {
        this.width = gridSize;
        this.height = gridSize;

        this.drawing_canvas[0].width = gridSize;
        this.drawing_canvas[0].height = gridSize;
        this.preview_canvas[0].width = gridSize;
        this.preview_canvas[0].height = gridSize;
        this.grid_canvas[0].width = gridSize;
        this.grid_canvas[0].height = gridSize;
        this.save_canvas[0].width = gridSize;
        this.save_canvas[0].height = gridSize;
        this.createSVG();
    }

    updateGridSizeEnum(el, pt_per_ft) {
        //based on the current scale, generate a list of potential grid sizes that wont fry the current device
        var step = 256;
        var pxSizes = [ 1024, 1536, 2048, 3072, 4096, 6144, 8192, 12288, 16384, 24576 ];
        var mobile = this.isMobile();
        var selected = el.val();

        //remove existing elements
        el.empty();

        for (var i = 0; i < pxSizes.length; i++) {
        	if (pxSizes[i] > 3072 && mobile) {
        		//do nothing
        	} else {
        		el.append(`<option value="${pxSizes[i]}"${(selected == pxSizes[i]) ? ' selected' : ''}>${Math.floor(pxSizes[i]/pt_per_ft)}'</option>`);	
        	}
        }

    }

    updatePtPerFt(ptPerFt) {
        this.pt_per_ft = ptPerFt;
    }

    updateSnapUnit(snapUnit) {
        this.snapUnit = snapUnit;
    }

    getScale() {
        return this.scale;
    }

    toggleSnapToGrid() {
        this.snapToGrid = !this.snapToGrid;
        if (this.snapToGrid) {
            this.grid_canvas.setLayer(this.name, { visible: true }).drawLayers();
        } else {
            this.grid_canvas.setLayer(this.name, { visible: false }).drawLayers();
        }
        return this.snapToGrid;
    }

    scalePosition(position) {
        return {
            x: position.x / this.scale,
            y: position.y / this.scale
        }
    }

    positionOnCanvas(position) {
        position = this.scalePosition(position);
        return this.snapPosition(position);
    }

    snapToHandle(position) {
        if (!this.handles) {
            return false;
        }

        for (var i = this.handles.length - 1; i >= 0; i--) {
            let handlePosition = { x: this.handles[i].x, y: this.handles[i].y };
            let distance = this.getDistanceBetweenPoints(position, handlePosition);
            if (distance <= 10) {
                return handlePosition;
            }
        }

        return false;
    }

    positionOnCanvasWithOffset(position) {
        position = this.scalePosition(position);
        position.x = this.positionOnCanvasX(position.x);
        position.y = this.positionOnCanvasY(position.y);

        return this.snapPosition(position);
    }

    positionOnCanvasX(x) {
        x = x - this.draw_space.offset().left / this.scale + this.draw_space.scrollLeft() / this.scale;
        return x;
    }

    positionOnCanvasY(y) {
        y = y - this.draw_space.offset().top / this.scale + this.draw_space.scrollTop() / this.scale;
        return y;
    }

    snapPosition(position) {
        let snapToHandlePosition = this.snapToHandle(position);
        if (snapToHandlePosition) {
            return snapToHandlePosition;
        }

        return {
            x: this.snapX(position.x),
            y: this.snapX(position.y),
        }
    }

    snapX(x) {
        if (!this.snapToGrid) {
            return x;
        }
        let new_x = Math.round(x / this.snapUnit) * this.snapUnit;
        return new_x;
    }

    snapY(y) {
        if (!this.snapToGrid) {
            return y;
        }
        let new_y = Math.round(y / this.snapUnit) * this.snapUnit;
        return new_y;
    }

    createSVG() {
        let data = 'data:image/svg+xml;charset=utf-8,<svg width="' + this.width / this.scale + '" height="' + this.height / this.scale + '" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> \
		    <defs> \
		        <pattern id="smallGrid" width="' + this.gridSizePts + '" height="' + this.gridSizePts + '" patternUnits="userSpaceOnUse"> \
		  			  <path d="M ' + this.gridSizePts + ' 0 L 0 0 0 ' + this.gridSizePts + '" fill="none" stroke="%23707070" stroke-width="1" stroke-opacity=".75"/> \
		  			</pattern> \
		  			<pattern id="grid" width="' + this.gridSizePts * 2 + '" height="' + this.gridSizePts * 2 + '" patternUnits="userSpaceOnUse"> \
		  			  <rect width="' + this.gridSizePts * 2 + '" height="' + this.gridSizePts * 2 + '" fill="url(%23smallGrid)"/> \
		  			  <path d="M ' + this.gridSizePts * 2 + ' 0 L 0 0 0 ' + this.gridSizePts * 2 + '" fill="none" stroke="%23707070" stroke-width="1"/> \
		  			</pattern> \
		    </defs> \
		    <rect width="100%" height="100%" fill="url(%23grid)" /> \
		</svg>';

        $('#img-src').attr('src', data);
        this.createGrid();
    }

    createGrid() {
        this.grid_canvas.removeLayer(this.name);
        this.grid_canvas.addLayer({
            type: 'image',
            name: this.name,
            x: 0,
            y: 0,
            width: this.width,
            height: this.height,
            fromCenter: false,
            disableEvents: true,
            source: $('#img-src')[0]
        });
        this.draw(this.grid_canvas);
    }

    draw(canvas) {
        let callback = function() {
            canvas.drawLayers();
        }
        requestAnimationFrame(callback);
    }

    getAngleOfLine(start, end) {
        let angle = Math.atan2(start.y - end.y, start.x - end.x) * 180 / Math.PI;
        return angle - 90;
    }

    getDistanceBetweenPoints(a, b) {
        return Math.sqrt(Math.pow((b.x - a.x), 2) + Math.pow((b.y - a.y), 2));
    }

    getLineData(p1, p2) {
        let dx = p2.x - p1.x;
        let dy = p2.y - p1.y;
        let length = Math.round(Math.sqrt(dx * dx + dy * dy));
        let length_ft = Math.floor(length / this.pt_per_ft);
        let length_in = Math.round(((length / this.pt_per_ft) - Math.floor(length / this.pt_per_ft)) * 12);
        let slope = dy / dx;
        let inverse_slope = -1 / slope;
        let text_anchor_x = ((p1.x + p2.x) / 2);
        let text_anchor_y = ((p1.y + p2.y) / 2);
        let text_pos_x = null;
        let text_pos_y = null;
        let inverse_text_pos_x = null;
        let inverse_text_pos_y = null;

        let angle = null;
        if (p1.x < p2.x) {
            angle = this.getAngleOfLine(p1, p2);
        } else if (p1.x > p2.x) {
            angle = this.getAngleOfLine(p2, p1);
        } else {
            angle = this.getAngleOfLine(p1, p2);
        }
        angle = angle - 90;

        if (Math.abs(inverse_slope) == Infinity) {
            text_pos_x = text_anchor_x;
            text_pos_y = text_anchor_y - 40;
            inverse_text_pos_x = text_anchor_x;
            inverse_text_pos_y = text_anchor_y + 40;
        } else {
            if (slope > 0) {
                text_pos_x = text_anchor_x + 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
                text_pos_y = text_anchor_y + inverse_slope * 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
                inverse_text_pos_x = text_anchor_x - 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
                inverse_text_pos_y = text_anchor_y - inverse_slope * 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
            } else {
                inverse_text_pos_x = text_anchor_x + 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
                inverse_text_pos_y = text_anchor_y + inverse_slope * 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
                text_pos_x = text_anchor_x - 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
                text_pos_y = text_anchor_y - inverse_slope * 40 * Math.sqrt(1 / (1 + (inverse_slope * inverse_slope)));
            }
        }
        let text_layer_name = name + '-length';

        return {
            length: length,
            length_ft: length_ft,
            length_in: length_in,
            slope: slope,
            text_pos_x: text_pos_x,
            text_pos_y: text_pos_y,
            inverse_text_pos_x: inverse_text_pos_x,
            inverse_text_pos_y: inverse_text_pos_y,
            text_anchor_x: text_anchor_x,
            text_anchor_y: text_anchor_y,
            text_layer_name: text_layer_name,
            angle: angle
        }
    }

    isMobile() {
        var check = false;
        (function(a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };
}