import { GridTool } from "./GridTool.js";

export class Tool {
    constructor(canvas, preview_canvas, options) {
        this.canvas = canvas;
        this.preview_canvas = preview_canvas;
        this.options = options;
        this.gridTool = options.gridTool || null;
        this.editTool = options.editTool || null;
        this.textTool = options.textTool || null;
        this.hiddenLayers = null;
        this.active = false;
        this.drawing = false;
        this.initialize_ids();
    }

    initialize_ids() {
        if (!Tool.ids) {
            Tool.ids = [];
        }

        return Tool.ids;
    }

    set id_prefix(value) {
        this._id_prefix = value.toUpperCase();
    }

    get id_prefix() {
        return this._id_prefix;
    }

    set current_id(id) {
        Tool.ids[this.id_prefix] = id;
    }

    get current_id() {
        return Tool.ids[this.id_prefix];
    }

    getId() {
        this.incrementId();
        return this.id_prefix + "-" + this.pad(this.current_id, 3);
    }

    incrementId() {
        switch (this.current_id) {
            case undefined:
                this.current_id = 1;
                break;
            default:
                this.current_id++;
        }
    }

    activate(options) {
        this.active = true;
        this.options = options;
        this.id_prefix = options.prefix;
        if (this.gridTool) {
            this.gridTool.getHandles();
        }
    }

    unhideHiddenLayers() {
        if (this.hiddenLayers) {
            this.canvas.setLayer(this.hiddenLayers, { visible: true });
            this.hiddenLayers = null;
        }
    }

    close() {
        this.preview_canvas.removeLayerGroup("preview");
        this.canvas.setLayers({ draggable: false });
        this.unhideHiddenLayers();
        this.active = false;
        this.drawing = false;
        this.startPosition = null;
        this.intersected_line = null;
        this.previewPosition = null;
        this.lastPreviewPosition = null;

        this.draw(this.canvas);
        this.draw(this.preview_canvas);
    }

    draw(canvas) {
        let callback = function () {
            canvas.drawLayers();
        };
        requestAnimationFrame(callback);
    }

    snapPosition(position) {
        if (this.gridTool) {
            return this.gridTool.positionOnCanvas(position);
        } else {
            return position;
        }
    }

    addText(options) {
        let canvas;
        let visible = options.visible === null ? true : options.visible;
        if (options.preview) {
            options.groups.push("preview");
            canvas = this.preview_canvas;
        } else {
            canvas = this.canvas;
        }
        this.textTool.addText({
            canvas: canvas,
            x: options.x,
            y: options.y,
            anchor: options.anchor,
            text: options.text,
            groups: options.groups,
            dragGroups: options.dragGroups,
            draggable: false,
            preview: options.preview || false,
            angle: options.angle,
            visible: visible,
            drawMeasurements: options.drawMeasurements,
        });
    }

    getDistanceBetweenPoints(a, b) {
        return Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
    }

    getAngleOfLine(start, end) {
        let angle =
            (Math.atan2(start.y - end.y, start.x - end.x) * 180) / Math.PI;
        return angle - 90;
    }

    uuid() {
        var uuid = "",
            i,
            random;
        for (i = 0; i < 32; i++) {
            random = (Math.random() * 16) | 0;

            if (i == 8 || i == 12 || i == 16 || i == 20) {
                uuid += "-";
            }
            uuid += (
                i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random
            ).toString(16);
        }
        return uuid;
    }

    sqr(x) {
        return x * x;
    }

    dist2(v, w) {
        return this.sqr(v.x - w.x) + this.sqr(v.y - w.y);
    }

    distToSegmentSquared(p, v, w) {
        var l2 = this.dist2(v, w);
        if (l2 == 0) return this.dist2(p, v);
        var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
        t = Math.max(0, Math.min(1, t));
        let intersect = {
            x: v.x + t * (w.x - v.x),
            y: v.y + t * (w.y - v.y),
        };
        return {
            distance: Math.sqrt(
                this.dist2(p, {
                    x: v.x + t * (w.x - v.x),
                    y: v.y + t * (w.y - v.y),
                })
            ),
            intersect: intersect,
        };
    }

    distToSegment(p, v, w) {
        return this.distToSegmentSquared(p, v, w);
    }

    delegate(object, method) {
        var shim = function () {
            method.apply(object, arguments);
        };
        return shim;
    }

    pad(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }
}
