import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../../api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function SettingsSidebar() {
  const [user, loading, error] = useAuthState(auth);
  const [active, setActive] = useState('');
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const handleClick = (e) => {
    return setActive(e.target.textContent);
  };

  return (
    <aside
      className="w-52 h-screen fixed overflow-y-auto"
      aria-label="Sidebar"
    >
      <div className="h-screen overflow-y-auto py-4 px-3 bg-gray-100 rounded">
        <ul className="space-y-2">
          <li>
            <Link
              to="/settings?type=sketch"
              className={`${
                query.get('type') === 'sketch' ? 'bg-header/25 hover:bg-header hover:bg-opacity-20 pointer-events-none' : ''
              } flex items-center p-2 text-base font-normal text-primaryText rounded-lg hover:bg-gray-400 hover:text-primaryText hover:bg-opacity-20 duration-100`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-gray-500 transition duration-75"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42"
                />
              </svg>

              <span className="ml-3 ">Sketch Settings</span>
            </Link>
          </li>

          <div className="divide-solid bg-primaryText/25 h-[1px] m-3"></div>
          <li>
            <Link
              to="/settings?type=integrations"
              className={`${
                query.get('type') === 'integrations' ? 'bg-header/25 hover:bg-header hover:bg-opacity-20 pointer-events-none' : ''
              } flex items-center p-2 text-base font-normal text-primaryText rounded-lg hover:bg-gray-400  hover:text-primaryText hover:bg-opacity-20 duration-100`}
            >
              <img
                className="w-6 h-6 text-gray-500 transition duration-75"
                src="https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/196734/JN_Logo_Full-Blue.png"
              />
              <span className="ml-3 ">JobNimbus</span>
            </Link>
          </li>
          {/* <li>
            {user ? (
              <div className="flex justify-evenly text-primaryText mt-5">
                <span className=" whitespace-nowrap select-none">Logout</span>
                <svg
                  onClick={() => navigate('/logout')}
                  className="w-6 h-6 mb-8 text-onPrimaryBgSofter hover:text-error cursor-pointer mt-auto transform hover:scale-110 duration-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : null}
          </li> */}
        </ul>
      </div>
    </aside>
  );
}
