import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import 'bootstrap';
import 'tw-elements';
import App from './App';
import { ToolProvider } from './Contexts/ToolContext';
import { AuthProvider } from './Contexts/AuthContext';

ReactDOM.render(
  <AuthProvider>
    <ToolProvider>
      <App />
    </ToolProvider>
  </AuthProvider>,
  document.getElementById('main')
);
