import "react-notifications-component/dist/theme.css";
import { ReactNotifications, Store } from "react-notifications-component";

export let notification = {
  title: "Success:",
  message: "configure",
  type: "danger",
  container: "bottom-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

export const notify = (type, title, message, container) =>
  Store.addNotification({
    ...notification,
    title: title,
    message: message,
    type: type,
    container: container ? container : "bottom-right",
  });
