import React, { useState, useEffect } from 'react';
import { getFirestore, query, getDocs, collection, where, addDoc, getDoc, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../../api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';

export default function JobNimbusSettings() {
  const [user, loading, error] = useAuthState(auth);
  const [value, loadingCollection, collectionError] = useCollection(query(collection(db, 'users'), where('uid', '==', user.uid)), {});
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (value && value.docs[0].data()) {
      setSettings(value.docs[0].data());
    }
  }, [loadingCollection]);

  async function saveSettings(newSettings) {
    console.log(newSettings);
    const docRef = doc(db, 'users', value.docs[0].id);
    const docSnap = await getDoc(docRef);

    await updateDoc(docRef, newSettings);
  }

  return (
    <div class="flex justify-center px-10 py-10">
      <div class="mb-3 w-full">
        <p className="text-xs my-2 text-onPrimaryBgSofter">Enable JobNimbus Integration</p>
        <label
          for="enableJobNimbusToggle"
          class="flex relative items-center mb-4 cursor-pointer max-w-fit"
        >
          <input
            type="checkbox"
            id="enableJobNimbusToggle"
            class="sr-only"
            checked={settings.jobNimbusIntegration !== undefined ? settings.jobNimbusIntegration.enabled : false}
            onClick={() => {
              setSettings({ ...settings, jobNimbusIntegration: { ...settings.jobNimbusIntegration, enabled: !settings.jobNimbusIntegration.enabled } });
              saveSettings({ ...settings, jobNimbusIntegration: { ...settings.jobNimbusIntegration, enabled: !settings.jobNimbusIntegration.enabled } });
            }}
          />
          <div class="w-11 h-6 bg-onPrimaryBgSofter rounded-full border border-onPrimaryBgSofter toggle-bg"></div>
          <span class="ml-3 font-medium text-onPrimaryBgSofter text-xs">
            {settings.jobNimbusIntegration && settings.jobNimbusIntegration.enabled ? settings.jobNimbusIntegration.enabled.toString() : ''}
          </span>
        </label>
        <input
          id="apiKeyInput"
          defaultValue={settings.jobNimbusIntegration && settings.jobNimbusIntegration.apiKey}
          onBlur={(e) => {
            setSettings({ ...settings, jobNimbusIntegration: { ...settings.jobNimbusIntegration, apiKey: e.target.value, enabled: true } });
            saveSettings({ ...settings, jobNimbusIntegration: { ...settings.jobNimbusIntegration, apiKey: e.target.value, enabled: true } });
          }}
          type="text"
          class="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700
         focus:bg-white 
         focus:border-header 
         focus:outline-header 
         focus:shadow-none
      "
          placeholder="API Key"
        />
      </div>
    </div>
  );
}
