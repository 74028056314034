import React from 'react';
import { auth } from '../api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

export default function DropdownMenu() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  return (
    <div class="flex justify-center">
      <div className="w-full">
        <div
          class="dropdown relative w-full pr-6
          "
        >
          <img
            className="
            dropdown-toggle 
            transition
  
          duration-150
          ease-in-out 
          flex
          items-center
          whitespace-nowrap
          w-[45px] 
          rounded-full 
          navbar-nav 
          mx-2 
          cursor-pointer 
          shadow-md"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            src={user && user.photoURL}
          />

          <ul
            class="
            dropdown-menu
            
            bg-white
            text-base
            z-50
            py-2
            list-none
            text-left
            
            shadow-lg
            mt-1
            
            border-none
        "
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <a
                //data-bs-toggle="modal"
                //data-bs-target="#settings-modal"
                //id="settings"
                class="
                cursor-pointer
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
            "
                onClick={() => navigate('/settings?type=sketch')}
              >
                Settings
              </a>
            </li>
            {/* <li>
              <a
                class="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
            "
                href="#"
              >
                Integrations
              </a>
            </li> */}
            <li>
              <a
                class="
                cursor-pointer
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
            "
                onClick={() => navigate('/logout')}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
