import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'jcanvas';
import './jcanvas-handles.js';
import { Vector } from './tools/Vector.js';
import { GridTool } from './tools/GridTool.js';
import { EditTool } from './tools/EditTool.js';
import { TextTool } from './tools/TextTool.js';
import { SelectorTool } from './tools/SelectorTool.js';
import { ScrollTool } from './tools/ScrollTool.js';
import { CircleTool } from './tools/CircleTool.js';
import { DoubleFixedGateTool } from './tools/DoubleFixedGateTool.js';
import { DoubleVariableGateTool } from './tools/DoubleVariableGateTool.js';
import { FenceTool } from './tools/FenceTool.js';
import { RectangleTool } from './tools/RectangleTool.js';
import { SingleFixedGateTool } from './tools/SingleFixedGateTool.js';
import { SingleVariableGateTool } from './tools/SingleVariableGateTool.js';
import { UndoTool } from './tools/UndoTool.js';
import { ArrowTool } from './tools/ArrowTool.js';

import { getUser } from './api/user';
import { createFile } from './api/jobnimbus.js';

var FileSaver = require('file-saver');

export async function load(user) {
  console.log('ready... imported');
  // $.getJSON('./package.json', function (data) {
  //   updateVersion(data.version);
  // });

  $('body').scrollTop(0).scrollLeft(0);
  $.urlParam = function (name) {
    var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
    results = results ? results : 0;

    return results[1] || 0;
  };

  var roll_gate_color = '#fd5f00';
  var cantilever_gate_color = '#f3f315';
  var v_track_gate_color = '#39ff14';

  var record_id = $.urlParam('record_id');
  var server = $.urlParam('server');
  var file = $.urlParam('file');
  console.log(file);
  var debug = $.urlParam('debug');

  function updateVersion(version) {
    $('.version').text(`v${version}`);
  }

  //GET SELECTORS
  var draw_space_selector = $('#draw_space');
  var body_selector = $('body');
  var canvas_selector = $('#canvas');
  var preview_canvas_selector = $('#preview-canvas');
  var grid_canvas_selector = $('#grid-canvas');
  var save_canvas_selector = $('#save-canvas');
  var tools_selector = $('#tools');
  var clear_selector = $('#clear');
  var settings_selector = $('#settings');
  var save_selector = $('#save');
  var upload_selector = $('#upload');
  var sidebar_selector = $('#sidebar');
  var content_selector = $('#content');
  var text_input_selector = $('#hidden-input');

  var body = body_selector[0];
  var draw_space = draw_space_selector[0];
  var canvas = canvas_selector[0];
  // var preview_canvas = preview_canvas_selector[0];
  var grid_canvas = grid_canvas_selector[0];
  var context = canvas.getContext('2d');
  var tools = tools_selector[0];
  var tools_modal = $('#tools-modal');
  var settings_modal = $('#settings-modal');
  var save_settings = $('#save-settings')[0];
  var settings = settings_selector[0];
  var clear_modal = $('#clear-modal');
  var clear_sketch = $('#clear-sketch')[0];
  var clear = clear_selector[0];
  var save = save_selector[0];
  var upload = upload_selector[0];

  //SCROLL BUTTONS
  var page_up = $('#paging-up')[0];
  var page_up_right = $('#paging-up-right')[0];
  var page_right = $('#paging-right')[0];
  var page_down_right = $('#paging-down-right')[0];
  var page_down = $('#paging-down')[0];
  var page_down_left = $('#paging-down-left')[0];
  var page_left = $('#paging-left')[0];
  var page_up_left = $('#paging-up-left')[0];

  var current_tool;
  var suppress_modals;

  //TOOLS
  var gridTool;
  var editTool;
  var textTool;
  var selectorTool;
  var scrollTool;
  var fenceTool;
  var rectangleTool;
  var circleTool;
  var singleVariableGateTool;
  var singleFixedGateTool;
  var doubleVariableGateTool;
  var doubleFixedGateTool;
  var arrowTool;
  var undoTool;

  //SETUP APP
  getLaunchOptions(user);

  //FUNCTIONS
  function initialize(settings) {
    //should contain scale, pt_per_ft, fontSize, gridSize, snapUnit
    console.log(settings);
    $(document).on('switchTool', switchTool);
    console.log('loading');
    let tools = {};

    //initialize tools
    gridTool = new GridTool(canvas_selector, preview_canvas_selector, grid_canvas_selector, save_canvas_selector, draw_space_selector, {
      width: canvas.width,
      height: canvas.height,
      scale: settings.scale,
      snapToGrid: true,
      pt_per_ft: settings.snapUnit
    });
    tools.gridTool = gridTool;

    gridTool.updatePtPerFt(settings.pt_per_ft);
    gridTool.updateGridSize(settings.gridSize);
    gridTool.adjustScale(settings.scale);
    $('#zoom-input')[0].value = settings.scale;
    $('#settingsGridSize')[0].value = settings.gridSize;
    $('#settingsSnapUnit')[0].value = settings.snapUnit;
    $('#settingsScale')[0].value = settings.pt_per_ft;

    var getPosition = (function (e) {
      var coordinates = {};
      var grid = gridTool;

      return function (e) {
        if (e instanceof MouseEvent) {
          coordinates = { x: e.clientX, y: e.clientY };
        } else if (e instanceof TouchEvent) {
          let type = e.type;

          if (type !== 'touchend') {
            coordinates = {
              x: e.touches[0].clientX,
              y: e.touches[0].clientY
            };
          } else {
            coordinates = {
              x: e.changedTouches[0].clientX,
              y: e.changedTouches[0].clientY
            };
          }
        }
        coordinates.x += window.scrollX;
        coordinates.y += window.scrollY;
        let gridCoordinates = grid.positionOnCanvasWithOffset(coordinates);

        return gridCoordinates;
      };
    })();

    editTool = new EditTool('#sidebar', '#content', '#canvas');

    textTool = new TextTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      editTool: editTool,
      fontSize: settings.fontSize,
      textInput: text_input_selector
    });
    $('#settingsFontSize')[0].value = settings.fontSize;
    tools.textTool = textTool;
    textTool.createHandlers();

    selectorTool = new SelectorTool(canvas_selector, preview_canvas_selector, {
      gridTool: gridTool
    });

    scrollTool = new ScrollTool(canvas_selector, preview_canvas_selector);

    fenceTool = new FenceTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      gridTool: gridTool,
      editTool: editTool,
      textTool: textTool
    });
    tools.fenceTool = fenceTool;
    fenceTool.createHandlers();

    arrowTool = new ArrowTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      gridTool: gridTool,
      editTool: editTool,
      textTool: textTool
    });
    tools.arrowTool = arrowTool;
    arrowTool.createHandlers();

    undoTool = new UndoTool(canvas_selector);

    rectangleTool = new RectangleTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      gridTool: gridTool,
      editTool: editTool,
      textTool: textTool
    });
    tools.rectangleTool = rectangleTool;
    rectangleTool.createHandlers();

    circleTool = new CircleTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      editTool: editTool,
      textTool: textTool
    });
    tools.circleTool = circleTool;
    circleTool.createHandlers();

    singleVariableGateTool = new SingleVariableGateTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      gridTool: gridTool,
      fenceTool: fenceTool,
      editTool: editTool,
      textTool: textTool
    });
    tools.singleVariableGateTool = singleVariableGateTool;
    singleVariableGateTool.createHandlers();

    singleFixedGateTool = new SingleFixedGateTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      gridTool: gridTool,
      fenceTool: fenceTool,
      editTool: editTool,
      textTool: textTool
    });
    tools.singleFixedGateTool = singleFixedGateTool;
    singleFixedGateTool.createHandlers();

    doubleVariableGateTool = new DoubleVariableGateTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      gridTool: gridTool,
      fenceTool: fenceTool,
      editTool: editTool,
      textTool: textTool
    });
    tools.doubleVariableGateTool = doubleVariableGateTool;
    doubleVariableGateTool.createHandlers();

    doubleFixedGateTool = new DoubleFixedGateTool(canvas_selector, preview_canvas_selector, {
      getPosition: getPosition,
      gridTool: gridTool,
      fenceTool: fenceTool,
      editTool: editTool,
      textTool: textTool
    });
    tools.doubleFixedGateTool = doubleFixedGateTool;
    doubleFixedGateTool.createHandlers();

    editTool.registerTools(tools);
  }

  function clearCanvas(canvas) {
    editTool.clear();
  }

  function saveCanvas(canvas) {
    var final = document.getElementById('save-canvas');
    var final_context = final.getContext('2d');

    var grid = document.getElementById('grid-canvas');
    var overlay = document.getElementById('canvas');

    final_context.fillStyle = '#FFFFFF';
    final_context.fillRect(0, 0, overlay.width, overlay.height);

    final_context.drawImage(grid, 0, 0);
    final_context.drawImage(overlay, 0, 0);

    let image_b64 = trim(canvas, final);

    let layers = canvas.getLayers(function (layer) {
      return layer.groups.hasAttribute([
        'fences',
        'arrows',
        'gates',
        'double-gates',
        'roll-gate',
        'v-track-gate',
        'cantilever-gate',
        'pool',
        'circles',
        'rectangles',
        'text-manual'
      ]);
    });

    let return_layers = [];
    for (var i = 0; i < layers.length; i++) {
      return_layers.push({
        length_ft: layers[i].data.length_ft,
        length_in: layers[i].data.length_in,
        x1: layers[i].x1,
        x2: layers[i].x2,
        y1: layers[i].y1,
        y2: layers[i].y2,
        groups: layers[i].groups,
        name: layers[i].data.friendly_name,
        drawParams: layers[i].data.drawParams
      });
    }

    var settings = {
      scale: 1,
      pt_per_ft: gridTool.pt_per_ft,
      fontSize: textTool.fontSize,
      gridSize: gridTool.gridSize,
      snapUnit: gridTool.snapUnit
    };

    let fileName = $('[data-test=search-input]').length > 0 ? `${$('[data-test=search-input]')[0].value} Fence Sketch` : 'fence-outline';
    console.log(fileName);
    saveAs(image_b64, fileName);

    // $.ajax({
    //   url: 'submit.php',
    //   type: 'POST',
    //   cache: false,
    //   dataType: 'json',
    //   contentType: 'application/json; charset=utf-8',
    //   data: JSON.stringify({
    //     layers: JSON.stringify(return_layers),
    //     image_b64: image_b64,
    //     record_id: record_id,
    //     server: server,
    //     file: file,
    //     settings: settings
    //   }),
    //   success: function (data, textStatus, jqXHR) {
    //     loginSuccess(data, textStatus, jqXHR);
    //   },
    //   error: function (data, textStatus, jqXHR) {
    //     console.log(image_b64);
    //     loginFailure(data, textStatus, jqXHR);
    //   }
    // });

    final_context.clearRect(0, 0, final.width, final.height);
  }

  function uploadCanvas(canvas) {
    var final = document.getElementById('save-canvas');
    var final_context = final.getContext('2d');

    var grid = document.getElementById('grid-canvas');
    var overlay = document.getElementById('canvas');

    final_context.fillStyle = '#FFFFFF';
    final_context.fillRect(0, 0, overlay.width, overlay.height);

    final_context.drawImage(grid, 0, 0);
    final_context.drawImage(overlay, 0, 0);

    let image_b64 = trim(canvas, final);

    let layers = canvas.getLayers(function (layer) {
      return layer.groups.hasAttribute([
        'fences',
        'arrows',
        'gates',
        'double-gates',
        'roll-gate',
        'v-track-gate',
        'cantilever-gate',
        'pool',
        'circles',
        'rectangles',
        'text-manual'
      ]);
    });

    let return_layers = [];
    for (var i = 0; i < layers.length; i++) {
      return_layers.push({
        length_ft: layers[i].data.length_ft,
        length_in: layers[i].data.length_in,
        x1: layers[i].x1,
        x2: layers[i].x2,
        y1: layers[i].y1,
        y2: layers[i].y2,
        groups: layers[i].groups,
        name: layers[i].data.friendly_name,
        drawParams: layers[i].data.drawParams
      });
    }

    var settings = {
      scale: 1,
      pt_per_ft: gridTool.pt_per_ft,
      fontSize: textTool.fontSize,
      gridSize: gridTool.gridSize,
      snapUnit: gridTool.snapUnit
    };

    let fileName = $('[data-test=search-input]').length > 0 ? `${$('[data-test=search-input]')[0].value} Fence Sketch.png` : 'fence-outline.png';
    console.log(fileName);
    console.log(user.jobNimbusIntegration.apiKey);
    let contact = JSON.parse(localStorage.getItem('jobNimbusContact'));
    console.log(contact);
    //saveAs(image_b64, fileName);
    createFile(user.jobNimbusIntegration.apiKey, image_b64, fileName, contact.jnid);
    // $.ajax({
    //   url: 'submit.php',
    //   type: 'POST',
    //   cache: false,
    //   dataType: 'json',
    //   contentType: 'application/json; charset=utf-8',
    //   data: JSON.stringify({
    //     layers: JSON.stringify(return_layers),
    //     image_b64: image_b64,
    //     record_id: record_id,
    //     server: server,
    //     file: file,
    //     settings: settings
    //   }),
    //   success: function (data, textStatus, jqXHR) {
    //     loginSuccess(data, textStatus, jqXHR);
    //   },
    //   error: function (data, textStatus, jqXHR) {
    //     console.log(image_b64);
    //     loginFailure(data, textStatus, jqXHR);
    //   }
    // });

    final_context.clearRect(0, 0, final.width, final.height);
  }

  function getLaunchOptions(user) {
    return loadDrawing(user, 'error');
    $.ajax({
      url: 'get.php',
      type: 'POST',
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        record_id: record_id,
        server: server,
        file: file
      }),
      success: function (data, textStatus, jqXHR) {
        loadDrawing(data, textStatus, jqXHR);
      },
      error: function (data, textStatus, jqXHR) {
        console.log(data);
        console.log(textStatus);
        loadDrawing(data, textStatus, jqXHR);
      }
    });
  }

  function loadDrawing(user, data, textStatus, jqXHR) {
    suppress_modals = true;

    let redraw_data = data.drawing_json ? data.drawing_json : null;
    console.log(user);
    let settings = user.sketchSettings
      ? { ...user.sketchSettings, pt_per_ft: 6 }
      : {
          scale: 1,
          pt_per_ft: 6,
          fontSize: 26,
          gridSize: 2048,
          snapUnit: 6
        };

    initialize(settings);
    registerListeners();

    if (redraw_data) {
      for (var i = 0; i < redraw_data.length; i++) {
        let type = redraw_data[i]['drawParams']['type'];
        let name = redraw_data[i]['drawParams']['name'];
        let position;
        let radius;
        let intersect;
        let intersected_line;
        let gate_line;
        let start;
        let end;
        let height;
        let width;
        let options = redraw_data[i]['drawParams']['options'];
        options.update = false;

        switch (type) {
          case 'circle':
            position = redraw_data[i]['drawParams']['position'];
            radius = redraw_data[i]['drawParams']['radius'];
            circleTool.createCircle(name, position, radius, options);
            break;

          case 'doubleFixedGate':
            intersect = redraw_data[i]['drawParams']['intersect'];
            intersected_line = redraw_data[i]['drawParams']['intersected_line'];
            doubleFixedGateTool.createGate(name, intersect, intersected_line, options);
            break;

          case 'doubleVariableGate':
            gate_line = redraw_data[i]['drawParams']['gate_line'];
            intersected_line = redraw_data[i]['drawParams']['intersected_line'];
            switchTool({
              tool: 'doubleVariableGate',
              color_override: redraw_data[i]['drawParams']['options']['strokeStyle']
            });
            switchTool({
              tool: 'rotate-' + redraw_data[i]['drawParams']['options']['rotation']
            });
            doubleVariableGateTool.drawGate(name, gate_line, intersected_line, options);
            break;

          case 'fence':
            start = redraw_data[i]['drawParams']['start'];
            end = redraw_data[i]['drawParams']['end'];
            fenceTool.createFence(name, start, end, options);
            break;

          case 'arrow':
            start = redraw_data[i]['drawParams']['start'];
            end = redraw_data[i]['drawParams']['end'];
            arrowTool.createArrow(name, start, end, options);
            break;

          case 'rectangle':
            position = redraw_data[i]['drawParams']['position'];
            height = redraw_data[i]['drawParams']['height'];
            width = redraw_data[i]['drawParams']['width'];
            rectangleTool.createRectangle(name, position, height, width, options);
            break;

          case 'singleFixedGate':
            intersect = redraw_data[i]['drawParams']['intersect'];
            intersected_line = redraw_data[i]['drawParams']['intersected_line'];
            singleFixedGateTool.createGate(name, intersect, intersected_line, options);
            break;

          case 'singleVariableGate':
            gate_line = redraw_data[i]['drawParams']['gate_line'];
            intersected_line = redraw_data[i]['drawParams']['intersected_line'];
            singleVariableGateTool.createGate(name, gate_line, intersected_line, options);
            break;

          case 'roll-gate':
            gate_line = redraw_data[i]['drawParams']['gate_line'];
            intersected_line = redraw_data[i]['drawParams']['intersected_line'];
            singleVariableGateTool.createGate(name, gate_line, intersected_line, options);
            break;

          case 'v-track-gate':
            gate_line = redraw_data[i]['drawParams']['gate_line'];
            intersected_line = redraw_data[i]['drawParams']['intersected_line'];
            singleVariableGateTool.createGate(name, gate_line, intersected_line, options);
            break;

          case 'cantilever-gate':
            gate_line = redraw_data[i]['drawParams']['gate_line'];
            intersected_line = redraw_data[i]['drawParams']['intersected_line'];
            singleVariableGateTool.createGate(name, gate_line, intersected_line, options);
            break;

          case 'text':
            options.canvas = canvas_selector;

            textTool.createText(options);
            break;
        }
      }
      canvas_selector.drawLayers();
    }

    switchTool({ tool: 'selector' });
    suppress_modals = false;
  }

  function loginSuccess(data, textStatus, jqXHR) {
    save.disabled = false;
    alert('Drawing saved. Click the refresh icon within TRUE Fence to view your selections.');
  }

  function loginFailure(data, textstatus, jqXHR) {
    save.disabled = false;
    alert('Unable to save. Please try again. If the issue persists, please contact support@constructtrue.com');
  }

  function switchTool(param) {
    console.log(param);
    let tool = param.tool;
    let color_override = param.color_override;
    let material = param.material;
    let mode = tool;

    let options = null;
    let activation_method = false;
    let do_not_close_modal = false;
    let do_not_close = false;
    let next_tool = null;

    if (mode == 'fence-existing') {
      activation_method = 'activate';
      next_tool = fenceTool;
      options = {
        strokeStyle: '#000000',
        strokeDash: [4],
        type: 'existing',
        prefix: 'EXISTING',
        drawMeasurements: false
      };
    } else if (mode == 'fence-tear-out') {
      activation_method = 'activate';
      next_tool = fenceTool;
      options = {
        strokeStyle: '#ff0000',
        strokeWidth: 12,
        strokeDash: [4],
        type: 'tear-out',
        prefix: 'TEAR-OUT',
        drawMeasurements: false
      };
    } else if (mode.includes('fence-')) {
      activation_method = 'activate';
      next_tool = fenceTool;
      options = {
        strokeStyle: color_override || '#4d4d22',
        type: 'fence',
        prefix: 'FENCE',
        drawMeasurements: false
      };
    } else if (mode == 'arrow') {
      activation_method = 'activate';
      next_tool = arrowTool;
      options = {
        strokeStyle: color_override || '#4d4d22',
        type: 'arrow',
        prefix: 'ARROW'
      };
    } else if (mode == 'singleVariableGate') {
      activation_method = 'activate';
      do_not_close_modal = true;
      if (!suppress_modals) {
        $('#rotation-single-tab').tab('show');
      }
      next_tool = singleVariableGateTool;
      options = {
        drawSwing: true,
        type: 'singleVariableGate',
        prefix: 'GATE',
        drawMeasurements: false
      };
    } else if (mode == 'rollGate') {
      activation_method = 'activate';
      next_tool = singleVariableGateTool;
      options = {
        strokeStyle: roll_gate_color,
        drawSwing: false,
        rotation: 1,
        type: 'roll-gate',
        prefix: 'ROLL-GATE',
        maxWidth: 24,
        drawMeasurements: false
      };
    } else if (mode == 'vTrackGate') {
      activation_method = 'activate';
      next_tool = singleVariableGateTool;
      options = {
        strokeStyle: v_track_gate_color,
        drawSwing: false,
        rotation: 1,
        type: 'v-track-gate',
        prefix: 'V-TRACK',
        maxWidth: 24,
        drawMeasurements: false
      };
    } else if (mode == 'cantileverGate') {
      activation_method = 'activate';
      next_tool = singleVariableGateTool;
      options = {
        strokeStyle: cantilever_gate_color,
        drawSwing: false,
        rotation: 1,
        type: 'cantilever-gate',
        prefix: 'CANTILEVER',
        maxWidth: 24,
        drawMeasurements: false
      };
    } else if (mode.includes('singleFixedGate-')) {
      activation_method = 'activate';
      do_not_close_modal = true;
      if (!suppress_modals) {
        $('#rotation-single-tab').tab('show');
      }
      next_tool = singleFixedGateTool;
      options = {
        fixedWidth: Number(mode.replace('singleFixedGate-', '')),
        type: 'singleFixedGate',
        prefix: 'GATE',
        drawMeasurements: false,
        rotation: 1
      };
    } else if (mode == 'doubleVariableGate') {
      activation_method = 'activate';
      next_tool = doubleVariableGateTool;
      do_not_close_modal = true;
      if (!suppress_modals) {
        $('#rotation-double-tab').tab('show');
      }
      options = {
        type: 'doubleVariableGate',
        prefix: 'DOUBLE-GATE',
        drawMeasurements: false
      };
    } else if (mode.includes('doubleFixedGate-')) {
      activation_method = 'activate';
      next_tool = doubleFixedGateTool;
      do_not_close_modal = true;
      if (!suppress_modals) {
        $('#rotation-double-tab').tab('show');
      }
      options = {
        fixedWidth: Number(mode.replace('doubleFixedGate-', '')),
        type: 'doubleFixedGate',
        prefix: 'DOUBLE-GATE',
        drawMeasurements: false
      };
    } else if (mode.includes('rotate-')) {
      activation_method = 'rotate';
      next_tool = current_tool;
      options = Number(mode.replace('rotate-', ''));
      do_not_close = true;
    } else if (mode == 'house') {
      activation_method = 'activate';
      next_tool = rectangleTool;
      options = {
        fillStyle: '#60462c80',
        type: 'House',
        prefix: 'HOUSE'
      };
    } else if (mode == 'patio') {
      activation_method = 'activate';
      next_tool = rectangleTool;
      options = {
        fillStyle: '#bfbfbf80',
        type: 'Patio',
        prefix: 'PATIO'
      };
    } else if (mode == 'pool') {
      activation_method = 'activate';
      next_tool = rectangleTool;
      options = {
        fillStyle: '#0303c180',
        type: 'Pool',
        prefix: 'POOL'
      };
    } else if (mode == 'tree') {
      activation_method = 'activate';
      next_tool = circleTool;
      options = {
        fillStyle: '#03c16280',
        type: 'Tree',
        prefix: 'TREE'
      };
    } else if (mode == 'selector') {
      activation_method = 'activate';
      next_tool = selectorTool;
      options = {};
      $('#selector-tab').tab('show');
    } else if (mode == 'text') {
      activation_method = 'activate';
      next_tool = textTool;
      options = {
        type: 'Text',
        prefix: 'TEXT'
      };
    } else if (mode == 'scroll') {
      activation_method = 'activate';
      next_tool = scrollTool;
      options = {};
    } else if (mode == 'zoom-in') {
      next_tool = current_tool;
      gridTool.adjustScale(0.1);
    } else if (mode == 'zoom-out') {
      next_tool = current_tool;
      gridTool.adjustScale(-0.1);
    } else if (mode == 'edit') {
      next_tool = editTool;
      if (!(current_tool == next_tool)) {
        activation_method = 'activate';
        options = {};
      } else {
        current_tool.close();
        next_tool = null;
      }
    }

    if (!do_not_close && current_tool) {
      current_tool.close();
    }

    current_tool = next_tool;

    if (activation_method) {
      current_tool[activation_method](options);
    }

    if (!do_not_close_modal) {
      tools_modal.modal('hide');
    }
  }

  function registerListeners() {
    //EVENT LISTENERS

    document.onkeydown = function (evt) {
      evt = evt || window.event;

      var isFocused = document.activeElement === $('[data-test=search-input]')[0];

      if (current_tool._id_prefix === 'TEXT' && evt.key !== 'Escape') return;
      if (isFocused) return;

      if ('key' in evt) {
        switch (evt.key) {
          case 'Escape' || 'Esc':
            $.event.trigger({
              type: 'switchTool',
              tool: 'selector',
              color_override: null
            });
            break;

          case 'h' || 'H':
            console.log(evt.key);
            $.event.trigger({
              type: 'switchTool',
              tool: 'house',
              color_override: null
            });
            break;

          case 'f' || 'F':
            console.log(evt.key);
            $.event.trigger({
              type: 'switchTool',
              tool: 'fence-standard',
              color_override: null
            });
            break;

          case 't' || 'T':
            console.log(evt.key);
            $.event.trigger({
              type: 'switchTool',
              tool: 'text',
              color_override: null
            });
            break;
          default:
            break;
        }
      }

      if (evt.ctrlKey && evt.key === 'z') {
        console.log('undo!');
        undoTool.undo();
      }
      return;
    };

    //on clicking clear button
    clear.addEventListener('click', function (e) {
      clear_modal.modal('show');
      //clearCanvas(canvas_selector);
    });

    clear_sketch.addEventListener('click', function (e) {
      //clear_modal.modal('show');
      clearCanvas(canvas_selector);
      clear_modal.modal('hide');
    });

    //on clicking settings button
    // settings.addEventListener('click', function (e) {
    //   gridTool.updateGridSizeEnum($('#settingsGridSize'), Number($('#settingsScale').val()));
    //   settings_modal.modal('show');
    // });

    //on changing scale option
    var scaleInput = $('#settingsScale')[0];
    scaleInput.addEventListener('change', function (e) {
      gridTool.updateGridSizeEnum($('#settingsGridSize'), Number($('#settingsScale').val()));
    });

    //on clicking save settings button
    save_settings.addEventListener('click', function (e) {
      console.log($('#settingsGridSize').val());
      let fontSize = $('#settingsFontSize').val();
      let scale = $('#settingsScale').val();
      let gridSize = $('#settingsGridSize').val();
      let snapUnit = $('#settingsSnapUnit').val();

      settings_modal.modal('hide');

      textTool.updateFontSize(fontSize);
      gridTool.updatePtPerFt(scale);
      gridTool.updateGridSize(gridSize);
      gridTool.updateSnapUnit(snapUnit);
    });

    //on clicking save button
    save.addEventListener('click', function (e) {
      if (save.disabled) {
        return true;
      }
      //save.disabled = true;
      saveCanvas(canvas_selector);
      return true;
    });

    upload.addEventListener('click', function (e) {
      if (upload.disabled) {
        return true;
      }
      //save.disabled = true;
      uploadCanvas(canvas_selector);
      return true;
    });

    //on clicking tools button
    tools.addEventListener('click', function (e) {
      tools_modal.modal('show');
    });

    //on changing zoom
    $('#zoom-input')[0].addEventListener('change', function (e) {
      let val = $('#zoom-input').val();
      gridTool.adjustScale(val);
    });

    //on clicking page up button
    page_up.addEventListener('click', function (e) {
      current_tool.pageUp();
    });

    //on clicking page up right button
    page_up_right.addEventListener('click', function (e) {
      current_tool.pageUpRight();
    });

    //on clicking page right button
    page_right.addEventListener('click', function (e) {
      current_tool.pageRight();
    });

    //on clicking page down right button
    page_down_right.addEventListener('click', function (e) {
      current_tool.pageDownRight();
    });

    //on clicking page down button
    page_down.addEventListener('click', function (e) {
      current_tool.pageDown();
    });

    //on clicking page down left button
    page_down_left.addEventListener('click', function (e) {
      current_tool.pageDownLeft();
    });

    //on clicking page left button
    page_left.addEventListener('click', function (e) {
      current_tool.pageLeft();
    });

    //on clicking page up left button
    page_up_left.addEventListener('click', function (e) {
      current_tool.pageUpLeft();
    });

    $('.tool').each(function () {
      let el = this;
      el.addEventListener('click', function (e) {
        $.event.trigger({
          type: 'switchTool',
          tool: this.id,
          color_override: $('#' + this.id).data('color')
        });
      });
    });
  }
}

jQuery('img.svg').each(function () {
  var $img = jQuery(this);
  var imgID = $img.attr('id');
  var imgClass = $img.attr('class');
  var imgURL = $img.attr('src');

  jQuery.get(
    imgURL,
    function (data) {
      // Get the SVG tag, ignore the rest
      var $svg = jQuery(data).find('svg');

      // Add replaced image's ID to the new SVG
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
      }

      // Replace image with new SVG
      $img.replaceWith($svg);
    },
    'xml'
  );
});

function trim(c_bounds, c_final) {
  var ctx = c_bounds[0].getContext('2d');

  var ctx_final = c_final.getContext('2d');

  var pixels = ctx.getImageData(0, 0, c_bounds[0].width, c_bounds[0].height);
  var l = pixels.data.length;
  var bound = {
    top: null,
    left: null,
    right: null,
    bottom: null
  };

  for (var i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      let x = (i / 4) % c_bounds[0].width;
      let y = ~~(i / 4 / c_bounds[0].width);

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }

  let padding = 100;

  bound.left = bound.left - padding;
  bound.top = bound.top - padding;

  var trimHeight = bound.bottom - bound.top + padding;
  var trimWidth = bound.right - bound.left + padding;

  var trimmed = ctx_final.getImageData(bound.left, bound.top, trimWidth, trimHeight);

  var new_canvas_node = document.createElement('canvas');
  var new_canvas = new_canvas_node.getContext('2d');
  new_canvas_node.id = 'canvas-trimmed';
  new_canvas.canvas.width = trimWidth;
  new_canvas.canvas.height = trimHeight;
  new_canvas.putImageData(trimmed, 0, 0);
  document.getElementById('hidden-div').appendChild(new_canvas_node);
  let b64 = $('#canvas-trimmed').getCanvasImage();
  document.getElementById('canvas-trimmed').remove();

  // open new window with trimmed image:
  return b64;
}

if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (what, i) {
    i = i || 0;
    var L = this.length;
    while (i < L) {
      if (this[i] === what) return i;
      ++i;
    }
    return -1;
  };
}

if (!Array.prototype.hasAttribute) {
  Array.prototype.hasAttribute = function (what, i) {
    i = i || 0;
    var L = this.length;
    while (i < L) {
      for (var x = 0; x < what.length; x++) {
        if (this[i].toLowerCase() === what[x].toLowerCase()) return true;
      }
      ++i;
    }
    return false;
  };
}
