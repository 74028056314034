import React, { useState, useEffect } from 'react';
import { getFirestore, query, getDocs, collection, where, addDoc, getDoc, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../../api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';

export default function SketchSettings() {
  const [user, loading, error] = useAuthState(auth);
  const [value, loadingCollection, collectionError] = useCollection(query(collection(db, 'users'), where('uid', '==', user.uid)), {});
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (value && value.docs[0].data().sketchSettings) {
      setSettings(value.docs[0].data().sketchSettings);
    }
  }, [loadingCollection]);

  async function saveSettings() {
    console.log(settings);
    const docRef = doc(db, 'users', value.docs[0].id);
    const docSnap = await getDoc(docRef);

    await updateDoc(docRef, {
      sketchSettings: {
        ...docSnap.data().sketchSettings,
        ...settings
      }
    });
  }

  return (
    <div className="py-10 px-10">
      <div className="mb-1">
        <h2 className="font-medium">Font Size</h2>
        <p className="text-xs my-2 text-onPrimaryBgSofter">Change the size of the text</p>
        <div class="flex w-full">
          <div class="mb-3 w-full">
            <select
              id="settingsFontSize"
              value={settings.fontSize}
              onBlur={async (e) => saveSettings()}
              onChange={(e) => setSettings({ ...settings, fontSize: parseInt(e.target.value) })}
              class="form-select 
                      appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="10">10</option>
              <option value="12">12</option>
              <option value="14">14</option>
              <option value="16">16</option>
              <option value="18">18</option>
              <option value="20">20</option>
              <option value="22">22</option>
              <option value="24">24</option>
              <option
                value="26"
                selected
              >
                26
              </option>
              <option value="28">28</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mb-1">
        <h2 className="font-medium">Scale</h2>
        <p className="text-xs my-2 text-onPrimaryBgSofter">
          Default grid scale is 6px / ft. Scale can only be changed before elements are drawn on the canvas. Changing the scale after drawing an object will
          cause unexpected results.
        </p>
        <div class="flex w-full">
          <div class="mb-3 w-full">
            <select
              value={settings.scale}
              onBlur={async (e) => saveSettings()}
              onChange={(e) => setSettings({ ...settings, scale: parseInt(e.target.value) })}
              id="settingsScale"
              class="form-select 
          appearance-none
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding bg-no-repeat
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="1">1px / ft</option>
              <option value="3">3px / ft</option>
              <option
                value="6"
                selected
              >
                6px / ft
              </option>
              <option value="9">9px / ft</option>
              <option value="12">12px / ft</option>
              <option value="15">15px / ft</option>
              <option value="18">18px / ft</option>
              <option value="21">21px / ft</option>
              <option value="24">24px / ft</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mb-1">
        <h2 className="font-medium">Grid Size</h2>
        <p className="text-xs my-2 text-onPrimaryBgSofter">
          Larger grid sizes may cause performance decreases on mobile devices. For very large grid sizes consider using a desktop rather than a tablet.
        </p>
        <div class="flex w-full">
          <div class="mb-3 w-full">
            <select
              value={settings.gridSize}
              id="settingsGridSize"
              onBlur={async (e) => saveSettings()}
              onChange={(e) => setSettings({ ...settings, gridSize: parseInt(e.target.value) })}
              class="form-select 
                      appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="1024">85'</option>
              <option value="1536">128'</option>
              <option
                value="2048"
                selected
              >
                170'
              </option>
              <option value="3072">256'</option>
              <option value="4096">340'</option>
              <option value="6144">512'</option>
              <option value="8192">680'</option>
              <option value="12288">1019'</option>
              <option value="16384">1365'</option>
              <option value="24576">2048'</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mb-1">
        <h2 className="font-medium">Snap to nearest</h2>
        <p className="text-xs my-2 text-onPrimaryBgSofter">
          Change the snap-to-grid behavior between nearest foot or nearest inch. Only affects newly drawn objects
        </p>
        <div class="flex w-full">
          <div class="w-full">
            <select
              value={settings.snapUnit}
              id="settingsSnapUnit"
              onBlur={async (e) => saveSettings()}
              onChange={(e) => setSettings({ ...settings, snapUnit: parseInt(e.target.value) })}
              class="form-select 
                      appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option
                value="6"
                selected
              >
                foot
              </option>
              <option value="1">inch</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
