import _ from 'lodash';
import { faker } from '@faker-js/faker';
import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getContacts } from '../api/jobnimbus';
import { useAuthContext, setUser } from '../Contexts/AuthContext';

const contactss = _.times(5, () => ({
  jnid: faker.datatype.uuid(),
  display_name: faker.company.companyName(),

  description: faker.company.catchPhrase(),
  image: faker.internet.avatar(),
  price: faker.finance.amount(0, 100, 2, '$')
}));

function ContactSearchBar() {
  const { auth, dispatch } = useAuthContext();
  const [contacts, setContacts] = useState([]);
  const [searchBar, setSearchBar] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (!auth.user) return;
    console.log(auth.user);
    getContacts(auth.user.jobNimbusIntegration.apiKey).then((data) => setContacts(data.results));
  }, []);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    localStorage.setItem('jobNimbusContact', JSON.stringify(item));
    console.log(item);
  };

  const handleOnFocus = () => {
    console.log('Focused');
  };

  const handleOnClear = () => {
    console.log('Cleared');
  };

  const formatResult = (item) => {
    console.log(item);
    return (
      <>
        <span
          key={item.jnid}
          style={{ display: 'block', textAlign: 'left' }}
        >
          {item.display_name}
        </span>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span> */}
      </>
    );
  };
  console.log(contacts);
  return (
    <div className="w-full mx-10">
      <ReactSearchAutocomplete
        placeholder="Search for contact"
        items={contacts}
        onSearch={handleOnSearch}
        onHover={handleOnHover}
        onSelect={handleOnSelect}
        onFocus={handleOnFocus}
        onClear={handleOnClear}
        autoFocus
        formatResult={formatResult}
        fuseOptions={{ keys: ['display_name'] }}
        resultStringKeyName="display_name"
        // styling={{
        //   height: '34px',
        //   border: '1px solid darkgreen',
        //   borderRadius: '4px',
        //   backgroundColor: 'white',
        //   boxShadow: 'none',
        //   hoverBackgroundColor: 'lightgreen',
        //   color: 'darkgreen',
        //   fontSize: '12px',
        //   fontFamily: 'Courier',
        //   iconColor: 'green',
        //   lineColor: 'lightgreen',
        //   placeholderColor: 'darkgreen',
        //   clearIconMargin: '3px 8px 0 0',
        //   zIndex: 2
        // }}
      />
    </div>
  );
}

export default ContactSearchBar;
