import React, { useEffect, useState } from 'react';
import { getFirestore, query, getDocs, collection, where, addDoc, getDoc, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from '../api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
//import '../selectFencing';
import Header from '../Components/Header';
import '../index.css';

import Toolbar from '../Components/Toolbar';
import ZoomControl from '../Components/ZoomControl';
import SettingsModal from '../Components/SettingsModal';
import ClearModal from '../Components/ClearModal';

const { load } = require('../selectFencing');

export default function Sketch() {
  const [user, loading, error] = useAuthState(auth);
  const [value, loadingCollection, collectionError] = useCollection(query(collection(db, 'users'), where('uid', '==', user.uid)), {});

  useEffect(async () => {
    if (user && value) {
      const docRef = doc(db, 'users', value.docs[0].id);
      const docSnap = await getDoc(docRef);
      console.log(docSnap);
      load({ ...docSnap.data(), id: docSnap.id });
    }
  }, [value]);

  return (
    <div
      id="sketch-component"
      className="default h-full w-full"
    >
      <nav
        id="sidebar"
        class="nano !bg-background text-primaryText !z-[1002]"
      >
        <ul
          id="sidebar-list"
          class="list-unstyled nano-content"
        ></ul>
      </nav>

      <Toolbar />
      <ZoomControl />
      <div
        id="content"
        class="active"
      >
        <div id="debugDiv"></div>
        <SettingsModal />
        <ClearModal />
        <div
          id="tools-modal"
          class="modal"
          role="dialog"
        >
          <div
            class="modal-dialog"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-body">
                <ul
                  class="nav nav-tabs text-white"
                  id="tab-list"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="selector-tab"
                      data-toggle="tab"
                      href="#selectors"
                      role="tab"
                      aria-controls="selectors"
                      aria-selected="true"
                    >
                      Selector
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="fence-tab"
                      data-toggle="tab"
                      href="#fences"
                      role="tab"
                      aria-controls="fences"
                      aria-selected="false"
                    >
                      Fences
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="gate-tab"
                      data-toggle="tab"
                      href="#gates"
                      role="tab"
                      aria-controls="gates"
                      aria-selected="false"
                    >
                      Gates
                    </a>
                  </li>
                  <li
                    hidden
                    class="nav-item"
                  >
                    <a
                      class="nav-link"
                      id="rotation-single-tab"
                      data-toggle="tab"
                      href="#rotations-single"
                      role="tab"
                      aria-controls="rotations"
                      aria-selected="false"
                    >
                      Rotate
                    </a>
                  </li>
                  <li
                    hidden
                    class="nav-item"
                  >
                    <a
                      class="nav-link"
                      id="rotation-double-tab"
                      data-toggle="tab"
                      href="#rotations-double"
                      role="tab"
                      aria-controls="rotations"
                      aria-selected="false"
                    >
                      Rotate
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="object-tab"
                      data-toggle="tab"
                      href="#objects"
                      role="tab"
                      aria-controls="objects"
                      aria-selected="false"
                    >
                      Objects
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="draw_space">
          <button
            id="paging-up"
            type="button"
            class="hidden paging-arrow paging paging-up"
          >
            <img
              class="tool-icon"
              src="./icons/u.svg"
            ></img>
          </button>
          <button
            id="paging-up-right"
            type="button"
            class="hidden paging-arrow paging paging-up-right"
          >
            <img
              class="tool-icon"
              src="./icons/ur.svg"
            ></img>
          </button>
          <button
            id="paging-right"
            type="button"
            class="hidden paging-arrow paging paging-right"
          >
            <img
              class="tool-icon"
              src="./icons/r.svg"
            ></img>
          </button>
          <button
            id="paging-down-right"
            type="button"
            class="hidden paging-arrow paging paging-down-right"
          >
            <img
              class="tool-icon"
              src="./icons/dr.svg"
            ></img>
          </button>
          <button
            id="paging-down"
            type="button"
            class="hidden paging-arrow paging paging-down"
          >
            <img
              class="tool-icon"
              src="./icons/d.svg"
            ></img>
          </button>
          <button
            id="paging-down-left"
            type="button"
            class="hidden paging-arrow paging paging-down-left"
          >
            <img
              class="tool-icon"
              src="./icons/dl.svg"
            ></img>
          </button>
          <button
            id="paging-left"
            type="button"
            class="hidden paging-arrow paging paging-left"
          >
            <img
              class="tool-icon"
              src="./icons/l.svg"
            ></img>
          </button>
          <button
            id="paging-up-left"
            type="button"
            class="hidden paging-arrow paging paging-up-left"
          >
            <img
              class="tool-icon"
              src="./icons/ul.svg"
            ></img>
          </button>
          <canvas
            id="save-canvas"
            className="!aspect-square absolute"
          ></canvas>
          <canvas
            id="grid-canvas"
            className="!aspect-square absolute"
          ></canvas>
          <canvas
            id="preview-canvas"
            className="!aspect-square absolute"
          ></canvas>
          <canvas
            id="canvas"
            className="!aspect-square absolute"
          ></canvas>
        </div>

        <img
          src="#"
          id="img-src"
        />

        <div
          id="hidden-div"
          class="hidden"
        ></div>
      </div>
    </div>
  );
}
