import React, { useEffect, useState } from 'react';
import { useAuthContext, setUser } from '../Contexts/AuthContext';
import DropdownMenu from './DropdownMenu';
import ContactSearchBar from './ContactSearchBar';
import { useNavigate } from 'react-router-dom';
import { Container, Table, Search } from 'semantic-ui-react';

const { getContacts } = require('../api/jobnimbus');

export default function Header(props) {
  //const { auth } = useAuthContext();
  const navigate = useNavigate();
  const { auth, dispatch } = useAuthContext();
  const [searchBar, setSearchBar] = useState('');
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (!auth.user) return;
    console.log(auth.user);
    //getContacts(auth.user.jobNimbusIntegration.apiKey).then((data) => setContacts(data.results));
  }, [auth]);

  return (
    <nav
      id="header"
      class="navbar navbar-expand !fixed top-0 right-0 left-0 !bg-white text-primaryText shadow-md !z-[1000]"
    >
      <div class="ml-0 mr-auto">
        <img
          onClick={() => navigate('/')}
          src={props.logo || 'https://mikekim.com/wp-content/uploads/2017/01/logo-here.png'}
          className="w-20 inline cursor-pointer"
        />
        {/* <a class="navbar-brand ml-2">Texas Select Fencing</a> */}
      </div>

      <input
        type="text"
        id="hidden-input"
        class="hidden"
      />

      <div className="mx-auto flex justify-center self-center w-full">
        {auth.user && auth.user.jobNimbusIntegration && !window.location.pathname.includes('settings') ? <ContactSearchBar /> : null}
      </div>

      <div className="flex">
        <div
          className="navbar-nav mx-2"
          // style={{ width: '10px' }}
        >
          <div
            class="navbar-nav"
            id="zoom"
            role="tabpanel"
            aria-labelledby="zoom-tab"
          ></div>
        </div>

        <div class="navbar-nav mx-2 hidden">
          <a
            id="tools"
            class="nav-item nav-link cursor-pointer text-primaryText"
          >
            Tools
          </a>
        </div>

        <div
          hidden={window.location.pathname.includes('settings')}
          class="navbar-nav mx-2 items-center"
          data-toggle="collapse"
        >
          <svg
            id="edit"
            class="w-8 h-8 max-w-[20px] cursor-pointer tool !p-0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </div>

        <div
          hidden={window.location.pathname.includes('settings')}
          class="navbar-nav mx-2 items-center"
        >
          <svg
            data-bs-toggle="modal"
            data-bs-target="#clear-modal"
            id="clear"
            class="w-8 h-8 max-w-[20px] cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>

        <div
          hidden={window.location.pathname.includes('settings')}
          class="navbar-nav mx-2 items-center"
        >
          <svg
            id="save"
            class="w-8 h-8 max-w-[20px] cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
            />
          </svg>
        </div>

        <div
          hidden={window.location.pathname.includes('settings')}
          class="navbar-nav mx-2 items-center"
        >
          <svg
            id="upload"
            class="w-8 h-8 max-w-[20px] cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
            />
          </svg>
        </div>
        {/* <div class="navbar-nav mx-2 items-center">
          <svg
            data-bs-toggle="modal"
            data-bs-target="#settings-modal"
            id="settings"
            className="navbar-nav w-8 max-w-[20px] cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
        </div> */}
      </div>
      <div className="mr-0 ml-5 flex">
        <DropdownMenu />
      </div>
    </nav>
  );
}
