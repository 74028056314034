import { Tool } from "./Tool.js";
import { GridTool } from "./GridTool.js";
/*
ARROW CLASS
This class handles drawing an arrow.
*/
export class ArrowTool extends Tool {
	constructor(canvas, preview_canvas, options){
		super(canvas, preview_canvas, options);

		this.resizeAt = null;
		this.resizeLastPosition = null;
		this.resizeLayerOriginalValues = null;
		this.lastPosition = null;
		this.startPosition = null;
	}

	activate(options){
		super.activate(options);
		this.createEventListeners();
	}

	createHandlers(){
		var tool = this;
		var getPosition = this.options.getPosition;

		this.clickHandler = (function(){
			return function(e){
				e.preventDefault();
				let position = getPosition(e);
				if (tool.drawing) {
					tool.completeExistingArrow(position);
				} else {
					tool.createNewArrow(position);
				}
				return true;
			}
		})();

		this.moveHandler = (function(){
			return function(e){
				e.preventDefault();
				let position = getPosition(e);
				tool.previewPosition = position;
				return true;
			}
		})();
	}

	createPreviewWatcher(){
		this.previewWatcher = requestAnimationFrame(this.delegate(this, this.previewTick));
	}

	destroyPreviewWatcher(){
		cancelAnimationFrame(this.previewWatcher);
	}

	previewTick(timestamp){
		if (!this.active) {return false};
		let currentPosition = this.previewPosition;
		let lastPosition = this.lastPreviewPosition;
		if (currentPosition == undefined || lastPosition == undefined) {
			//do nothing
		} else if (timestamp < this.lastPreviewTimestamp + 50){
			//do nothing
		} else if (currentPosition != lastPosition){
			this.preview(currentPosition);
		}
		this.createPreviewWatcher();
	}

	preview(position){
		this.lastPreviewTimestamp = performance.now();
		this.lastPreviewPosition = position;
		let preview_started = !(this.preview_canvas.getLayerGroup('preview') == undefined);

		this.drawArrow('preview',this.startPosition,position, {
			strokeStyle: 'rgba(255, 165, 165, .5)',
			preview: true,
			update: preview_started
		});

		this.draw(this.preview_canvas);
	}

	createEventListeners(){
		this.canvas[0].addEventListener('touchstart',this.clickHandler,false);
		this.canvas[0].addEventListener('touchend',this.clickHandler,false);
    	this.canvas[0].addEventListener('touchmove',this.moveHandler,false);
    	this.canvas[0].addEventListener('mouseup',this.clickHandler,false);
    	this.canvas[0].addEventListener('mousemove',this.moveHandler,false);
		return true;
	}

	destroyEventListeners(){
		this.canvas[0].removeEventListener('touchstart',this.clickHandler,false);
		this.canvas[0].removeEventListener('touchend',this.clickHandler,false);
    	this.canvas[0].removeEventListener('touchmove',this.moveHandler,false);
    	this.canvas[0].removeEventListener('mouseup',this.clickHandler,false);
    	this.canvas[0].removeEventListener('mousemove',this.moveHandler,false);
		return true;
	}

	close(){
		super.close();
		this.destroyPreviewWatcher();
		this.destroyEventListeners();
	}

	createNewArrow(position){
		this.drawing = true;
		this.startPosition = position;
		this.lastPreviewPosition = { x: 0, y: 0 };
		this.createPreviewWatcher();
	}

	completeExistingArrow(position){
		let name = this.uuid();
		let opts = this.options
		opts.friendly_name = this.getId();

		this.createArrow(name, this.startPosition, position, opts);
		this.activate(this.options);
	}

	createArrow(name,start,end,options){
		this.activate(options);
		let arrow = this.drawArrow(name, start, end, options);
		this.editTool.add_object(arrow);
		this.close();
		return arrow;
	}

	drawArrow(name,start,end,options){
		let strokeStyle = options.strokeStyle || 'rgba(255, 0, 0, 1)';
		let strokeWidth = options.strokeWidth || 4;
		let strokeDash = options.strokeDash || null;
		let groups = [name, 'arrows', options.type];
		if (options.preview) {
			groups.push('preview');
		}
		let dragGroups = (name == 'preview') ? null : [name];
		let metadata = this.gridTool.getLineData(start,end);
		let canvas = (options.preview) ? this.preview_canvas : this.canvas;
		let update = options.update && (canvas.getLayer(name) != undefined) || false;
		let friendly_name = options.friendly_name || 'Arrow';
		let opts = Object.assign({},options);

		let x1 = start.x;
		let y1 = start.y;
		let x2 = end.x;
		let y2 = end.y;

		if (update) {
			let old_line = canvas.getLayer(name);
			let old_x1 = old_line.x1;
			let old_y1 = old_line.y1;
			let old_x2 = old_line.x2;
			let old_y2 = old_line.y2;

			x1 = '+='+(start.x - old_x1);
			y1 = '+='+(start.y - old_y1);
			x2 = '+='+(end.x - old_x2);
			y2 = '+='+(end.y - old_y2);
		}

		let line = {
			name: name,
			type: 'line',
			strokeStyle: strokeStyle,
			strokeWidth: strokeWidth,
			strokeDash: strokeDash,
			endArrow: true,
			arrowRadius: 15,
			index: -1,
			x1: x1,
			y1: y1,
			x2: x2,
			y2: y2,
			groups: groups,
			dragGroups: dragGroups,
			draggable: false,
			handle: {
				type: 'arc',
				fillStyle: '#fff',
				strokeStyle: '#c33',
				strokeWidth: 2,
				radius: 3
			},
			handlestart: this.delegate(this, this.resizeStart),
			handlemove: this.delegate(this, this.resize),
			handlestop: this.delegate(this, this.resizeEnd),
			data: {
				length: metadata.length,
				length_ft: metadata.length_ft,
				length_in: metadata.length_in,
				slope: metadata.slope,
				text_pos_x: metadata.text_pos_x,
				text_pos_y: metadata.text_pos_y,
				type: this.options.type,
				friendly_name: friendly_name
			}
		}

		line.data.drawParams = {
			name: name,
			start: start,
			end: end,
			options: opts,
			type: 'arrow'
		};

		if (update) {
			canvas.setLayer(name,line);
			canvas.removeLayerGroup(line.name+'-text');
		} else {
			canvas.addLayer(line);
		}
		let layer = canvas.getLayer(name);
		
		return layer;
	}

	highlight(name){
		let layer = this.canvas.getLayer(name);
		this.canvas.removeLayerGroup('highlight');
		let line = {
			name: 'highlight',
			type: 'line',
			strokeStyle: 'rgba(0,0,0,1)',
			strokeWidth: 4,
			shadowBlur: 8,
			shadowColor: 'rgba(243,243,21,1)',
			endArrow: true,
			arrowRadius: 15,
			index: 999,
			x1: layer.x1,
			y1: layer.y1,
			x2: layer.x2,
			y2: layer.y2,
			groups: ['highlight'],
			draggable: false
		};
		this.canvas.addLayer(line);
		this.draw(this.canvas);
	}

	resizeStart(layer){
		if (this.drawing) {
			return false;
		}

		this.resizeLastUpdate = new Date().getTime();

		this.resizeLayerOriginalValues = {
			name: layer.name,
			start: { x: layer.x1, y: layer.y1 },
			end: { x: layer.x2, y: layer.y2 }
		};

		let clickLocation = this.snapPosition({ x: layer.eventX, y: layer.eventY });
		let dist_to_start = this.getDistanceBetweenPoints(clickLocation, { x: layer.x1, y: layer.y1 });
		let dist_to_end = this.getDistanceBetweenPoints(clickLocation, { x: layer.x2, y: layer.y2 });

		if (dist_to_start < dist_to_end) {
			this.resizeAt = 'start';
			this.resizeLastPosition = { x: layer.x1, y: layer.y1 };
		} else {
			this.resizeAt = 'end';
			this.resizeLastPosition = { x: layer.x2, y: layer.y2 };
		}
	}

	resize(layer){
		if (this.drawing) {
			return false;
		}
		let time = new Date().getTime();

		if (time - this.resizeLastUpdate >= 75) {
			this.resizeLastUpdate = time;
			let new_point = this.snapPosition({ x: layer.eventX, y: layer.eventY });
			let pos_start = null;
			let pos_end = null;
	
			this.resizeLastPosition = { x: new_point.x, y: new_point.y };
	
			if (this.resizeAt == 'start') {
				pos_start = new_point;
				pos_end = { x: layer.x2, y: layer.y2 };
			} else {
				pos_start = { x: layer.x1, y: layer.y1 };
				pos_end = new_point;
			}
	
			let opts = Object.assign({},layer.data.drawParams.options,{update:true});
			this.drawArrow(layer.name, pos_start, pos_end, opts);
			this.draw(this.canvas);
			this.editTool.update_object(layer);
		}
		return true;
	}

	resizeEnd(layer){
		if (this.drawing) {
			return false;
		}
		
		this.resizeAt = null;
		this.editTool.update_object(layer);
		this.draw(this.canvas);
	}
}