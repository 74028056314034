import { GridTool } from "./GridTool.js";
import { TextTool } from "./TextTool.js";
import { CircleTool } from "./CircleTool.js";
import { DoubleFixedGateTool } from "./DoubleFixedGateTool.js";
import { DoubleVariableGateTool } from "./DoubleVariableGateTool.js";
import { FenceTool } from "./FenceTool.js";
import { RectangleTool } from "./RectangleTool.js";
import { SingleFixedGateTool } from "./SingleFixedGateTool.js";
import { SingleVariableGateTool } from "./SingleVariableGateTool.js";
import { Vector } from "./Vector.js";

export class EditTool {
  constructor(sidebar, content, canvas) {
    this.sidebar = $(sidebar);
    this.sidebar_list = $(sidebar + " ul");
    this.content = $(content);
    this.canvas = $(canvas);
    this.contentCreator = (function () {
      return function () {
        let form = null;
        let type = $(this).data().type;

        let objects_form = `<form>
					<button type="submit" class="btn btn-danger btn-block pop-delete !bg-[#c82333] hover:!bg-opacity-80"">Delete</button>
					<button type="cancel" class="btn btn-info btn-block pop-cancel">Cancel</button>
				</form>`;

        let text_form = `<form>
					<div class="form-group">
						<label for="text">Text</label>
						<textarea rows="4" class="form-control" id="text-input">${
              $(this).data().text
            }</textarea>

						<label for="rotate">Rotation (degrees)</label>
						<input type="number" min="-360" max="360" class="form-control" id="rotate-input" value="${
              $(this).data().angle || 0
            }">
					</div>
					<button type="submit" class="btn btn-danger btn-block pop-delete !bg-[#c82333] hover:!bg-opacity-80"">Delete</button>
					<button type="cancel" class="btn btn-info btn-block pop-cancel">Cancel</button>
					<button type="submit" class="btn btn-success btn-block pop-save-text !bg-[#218838] hover:!bg-opacity-80">Save</button>
				</form>`;

        let gate_form = `<form>
					<div class="form-group">
						<label class="sr-only" for="gateColor">Gate Color</label>
						<div class="input-group mb-2 mr-sm-2">
							<div class="input-group-prepend">
								<div class="input-group-text">Color</div>
							</div>
							<input type="color" class="form-control form-control-color" id="gateColor" value="${
                $(this).data().color
              }" title="Choose a color" style="width:167px;">
						</div>
						<div class="custom-control custom-switch">
  							<input class="custom-control-input showMeasurements" type="checkbox" id="showMeasurements" ${
                  $(this).data().drawMeasurements ? "checked" : ""
                }>
  							<label class="custom-control-label" for="showMeasurements">Show Measurements</label>
						</div>
					</div>
					<button type="submit" class="btn btn-danger btn-block pop-delete !bg-[#c82333] hover:!bg-opacity-80"">Delete</button>
					<button type="cancel" class="btn btn-info btn-block pop-cancel">Cancel</button>
					<button type="submit" class="btn btn-success btn-block pop-save-gate !bg-[#218838] hover:!bg-opacity-80">Save</button>
				</form>`;

        let fence_form = `<form class="form-inline">
					<div class="input-group mb-3">
						<label class="sr-only" for="editPanelFeet">Feet</label>
						<div class="input-group mb-2 mr-sm-2">
							<div class="input-group-prepend">
								<div class="input-group-text">Ft</div>
							</div>
							<input type="text" class="form-control" id="editPanelFeet" value="${
                $(this).data().length_ft
              }">
						</div>

						<label class="sr-only" for="editPanelInches">Inches</label>
						<div class="input-group mb-2 mr-sm-2">
							<div class="input-group-prepend">
								<div class="input-group-text">In</div>
							</div>
							<input type="text" class="form-control" id="editPanelInches" value="${
                $(this).data().length_in
              }">
						</div>

						<label class="sr-only" for="editPanelGroup">Group</label>
						<div class="input-group mb-2 mr-sm-2">
							<div class="input-group-prepend">
								<div class="input-group-text">Group</div>
							</div>
							<select class="form-control" id="editPanelGroup">
								<option value="" ${
                  $(this).data().mergeGroup == "" ||
                  $(this).data().mergeGroup === null ||
                  $(this).data().mergeGroup === undefined
                    ? "selected"
                    : ""
                } disabled hidden></option>
								<option value="A" ${
                  $(this).data().mergeGroup == "A" ? "selected" : ""
                }>A</option>
								<option value="B" ${
                  $(this).data().mergeGroup == "B" ? "selected" : ""
                }>B</option>
								<option value="C" ${
                  $(this).data().mergeGroup == "C" ? "selected" : ""
                }>C</option>
								<option value="D" ${
                  $(this).data().mergeGroup == "D" ? "selected" : ""
                }>D</option>
								<option value="E" ${
                  $(this).data().mergeGroup == "E" ? "selected" : ""
                }>E</option>
								<option value="F" ${
                  $(this).data().mergeGroup == "F" ? "selected" : ""
                }>F</option>
								<option value="G" ${
                  $(this).data().mergeGroup == "G" ? "selected" : ""
                }>G</option>
								<option value="H" ${
                  $(this).data().mergeGroup == "H" ? "selected" : ""
                }>H</option>
								<option value="I" ${
                  $(this).data().mergeGroup == "I" ? "selected" : ""
                }>I</option>
								<option value="J" ${
                  $(this).data().mergeGroup == "J" ? "selected" : ""
                }>J</option>
							</select>
						</div>

						<label class="sr-only" for="lineColor">Line Color</label>
						<div class="input-group mb-2 mr-sm-2 !w-full inline-flex">
							<div class="input-group-prepend w-1/2">
								<div class="input-group-text">Color</div>
							</div>
							<input type="color" class="form-control form-control-color w-1/2" id="lineColor" value="${
                $(this).data().color
              }" title="Choose a color">
						</div>
					</div>
					<div class="input-group mb-3">
						<div class="custom-control custom-switch">
  							<input class="custom-control-input showMeasurements !cursor-pointer" type="checkbox" id="showMeasurements" ${
                  $(this).data().drawMeasurements ? "checked" : ""
                }>
  							<label class="custom-control-label !cursor-pointer" for="showMeasurements">Show Measurements</label>
						</div>
					</div>
					<button type="submit" class="btn btn-danger btn-block pop-delete !bg-[#c82333] hover:!bg-opacity-80">Delete</button>
					<button type="cancel" class="btn btn-info btn-block pop-cancel hover:!bg-opacity-80">Cancel</button>
					<button type="submit" class="btn btn-success btn-block pop-save-fence !bg-[#218838] hover:!bg-opacity-80">Save</button>
				</form>`;

        switch (type) {
          case "circle":
            form = objects_form;
            break;

          case "doubleFixedGate":
            form = gate_form;
            break;

          case "doubleVariableGate":
            form = gate_form;
            break;

          case "fence":
            form = fence_form;
            break;

          case "arrow":
            form = objects_form;
            break;

          case "rectangle":
            form = objects_form;
            break;

          case "singleFixedGate":
            form = gate_form;
            break;

          case "singleVariableGate":
            form = gate_form;
            break;

          case "roll-gate":
            form = gate_form;
            break;

          case "v-track-gate":
            form = gate_form;
            break;

          case "cantilever-gate":
            form = gate_form;
            break;

          case "text":
            form = text_form;
            break;
        }

        return form;
      };
    })();
    this.fontSize = 16;
  }

  activate(options) {
    this.show_sidebar();
  }

  registerTools(tools) {
    this.gridTool = tools.gridTool;
    this.textTool = tools.textTool;
    this.circleTool = tools.circleTool;
    this.doubleFixedGateTool = tools.doubleFixedGateTool;
    this.doubleVariableGateTool = tools.doubleVariableGateTool;
    this.fenceTool = tools.fenceTool;
    this.rectangleTool = tools.rectangleTool;
    this.singleFixedGateTool = tools.singleFixedGateTool;
    this.singleVariableGateTool = tools.singleVariableGateTool;
    this.arrowTool = tools.arrowTool;
  }

  show_sidebar() {
    this.sidebar.addClass("active");
    this.content.removeClass("active");
    $("html").addClass("fixed");
    $("body").addClass("fixed");
  }

  add_object(object) {
    let li_node = '<li id="' + object.name + '"></li>';
    let data = this.getData(object);
    let node = data.node;

    let li_el = $(li_node).appendTo(this.sidebar_list);
    let a_el = $(node).appendTo(li_el);
    a_el.data(data);

    a_el.popover({
      html: true,
      content: this.contentCreator,
      placement: "right",
      container: "body",
      boundary: "viewport",
      sanitize: false,
      title: function () {
        return $(this).data().friendly_name;
      },
    });

    $(a_el).on("inserted.bs.popover", this.delegate(this, this.create_popover));
    this.sidebar_list[0].addEventListener(
      "click",
      this.delegate(this, this.select_object)
    );
  }

  getData(object) {
    let data = {};
    let node;
    let nodeText;
    let mergeGroup;
    let mergeText;

    if (object["data"]["drawParams"]["type"] == "text") {
      nodeText = object.data.friendly_name;
      node =
        '<a href="#" data-toggle="popover" class="popover_list">' +
        nodeText +
        "</a>";
      data = {
        name: object["name"],
        text: object["text"],
        type: object["data"]["drawParams"]["type"],
        friendly_name: object["data"]["friendly_name"],
        node: node,
        nodeText: nodeText,
        angle: object["rotate"],
      };
    } else if (
      [
        "fence",
        "doubleFixedGate",
        "doubleVariableGate",
        "singleFixedGate",
        "singleVariableGate",
      ].includes(object["data"]["drawParams"]["type"])
    ) {
      mergeGroup = object["data"]["drawParams"]["options"]["mergeGroup"];
      mergeText = mergeGroup == undefined ? "" : "-" + mergeGroup;
      nodeText = `${object.data.friendly_name}${mergeText} ${object.data.length_ft}' ${object.data.length_in}\"`;
      node = `<a href="#" data-toggle="popover" class="popover_list">${nodeText}</a>`;
      data = {
        name: object["name"],
        length_ft: object["data"]["length_ft"],
        length_in: object["data"]["length_in"],
        type: object["data"]["drawParams"]["type"],
        friendly_name: object["data"]["friendly_name"],
        color: object["data"]["drawParams"]["options"]["strokeStyle"],
        drawMeasurements:
          object["data"]["drawParams"]["options"]["drawMeasurements"],
        mergeGroup: mergeGroup,
        node: node,
        nodeText: nodeText,
      };
    } else {
      nodeText = `${object.data.friendly_name}`;
      node = `<a href="#" data-toggle="popover" class="popover_list">${nodeText}</a>`;
      data = {
        name: object["name"],
        length_ft: object["data"]["length_ft"],
        length_in: object["data"]["length_in"],
        type: object["data"]["drawParams"]["type"],
        friendly_name: object["data"]["friendly_name"],
        node: node,
        nodeText: nodeText,
      };
    }
    return data;
  }

  update_object(object) {
    let data = this.getData(object);
    let nodeText = data.nodeText;
    let mergeGroup = data.mergeGroup;
    let mergeText = mergeGroup == undefined ? "" : "-" + mergeGroup;

    let a_node_text = `${object.data.friendly_name}${mergeText} ${object.data.length_ft}' ${object.data.length_in}"`;
    $("#" + object.name + "> a").text(nodeText);
    $("#" + object.name + "> a").data(data);
  }

  saveText(e) {
    e.preventDefault();
    let name = this.selected.data().name;
    let text = $("#text-input").val();
    let rotate = $("#rotate-input").val();
    let newData = this.canvas.getLayer(name).data;
    newData.drawParams.options.angle = rotate;
    this.canvas.setLayer(name, {
      text: text,
      rotate: rotate,
      data: newData,
    });
    this.selected.data("friendly_name", `"${text}"`);
    this.selected.data("text", text);
    this.selected.data("angle", rotate);
    this.selected.text(`"${text}"`);

    this.canvas.removeLayerGroup("highlight");
    this.canvas.drawLayers();
    this.selected.popover("hide");
  }

  saveFence(e) {
    e.preventDefault();
    let name = this.selected.data().name;
    let layer = this.canvas.getLayer(name);
    let drawMeasurements = this.selected.data().drawMeasurements;
    let mergeGroup = $("#editPanelGroup option:selected").text();
    let new_ft = Number($("#editPanelFeet").val());
    let new_in = Number($("#editPanelInches").val());
    let color = $("#lineColor").val();
    let distance =
      this.gridTool.pt_per_ft * new_ft +
      (this.gridTool.pt_per_ft / 12) * new_in;
    let opts = Object.assign({}, layer.data.drawParams.options, {
      update: true,
      strokeStyle: color,
      drawMeasurements: drawMeasurements,
      mergeGroup: mergeGroup,
    });

    let vector = new Vector(layer.x2 - layer.x1, layer.y2 - layer.y1);
    vector.normalize().multiply(distance);

    let original_start = new Vector(layer.x1, layer.y1);
    let new_end = Vector.add(original_start, vector);

    this.canvas.removeLayerGroup("highlight");
    this.selected.popover("hide");

    let newLayer = this.fenceTool.drawFence(
      name,
      original_start,
      new_end,
      opts
    );
    this.update_object(newLayer);
    this.canvas.drawLayers();
  }

  saveGate(e) {
    e.preventDefault();
    let type = this.selected.data().type;
    let name = this.selected.data().name;
    let layer = this.canvas.getLayer(name);
    let drawMeasurements = this.selected.data().drawMeasurements;
    let color = $("#gateColor").val();
    let opts = Object.assign({}, layer.data.drawParams.options, {
      edit: true,
      update: true,
      strokeStyle: color,
      drawMeasurements: drawMeasurements,
    });
    console.log(layer.data.drawParams.options);
    console.log(opts);
    this.canvas.removeLayerGroup("highlight");
    this.selected.popover("hide");

    let newLayer;
    if (type === "singleFixedGate") {
      newLayer = this.singleFixedGateTool.drawGate(
        name,
        layer.data.drawParams.intersect,
        layer.data.drawParams.intersected_line,
        opts
      );
    } else if (type === "doubleFixedGate") {
      newLayer = this.doubleFixedGateTool.drawGate(
        name,
        layer.data.drawParams.intersect,
        layer.data.drawParams.intersected_line,
        opts
      );
    } else if (type === "doubleVariableGate") {
      newLayer = this.doubleVariableGateTool.drawGate(
        name,
        layer.data.drawParams.gate_line,
        layer.data.drawParams.intersected_line,
        opts
      );
    } else {
      newLayer = this.singleVariableGateTool.drawGate(
        name,
        layer.data.drawParams.gate_line,
        layer.data.drawParams.intersected_line,
        opts
      );
    }

    this.update_object(newLayer);
    this.canvas.drawLayers();
  }

  toggleShowMeasurements(e) {
    let state = e.target.checked;
    let name = this.selected.data().name;
    let layer = this.canvas.getLayers(function (layer) {
      return layer.groups.hasAttribute([name + "-text"]);
    })[0];
    this.canvas.setLayer(layer, {
      visible: state,
    });

    this.selected.data().drawMeasurements = state;
    this.canvas.drawLayers();
  }

  cancel(e) {
    e.preventDefault();
    this.canvas.removeLayerGroup("highlight");
    this.canvas.drawLayers();
    this.selected.popover("hide");
  }

  delete(e) {
    e.preventDefault();
    let name = this.selected.data().name;
    //console.log(this.selected);
    this.canvas.removeLayerGroup(name);
    this.canvas.removeLayerGroup("highlight");
    this.canvas.drawLayers();

    this.selected.popover("hide");
    this.selected.parent().remove();
  }

  remove_object(object) {
    $("#" + object.name).remove();
  }

  clear() {
    this.canvas.removeLayers();
    this.canvas.drawLayers();
    this.sidebar_list.empty();
    this.close();
  }

  select_object(e) {
    e.preventDefault();
    this.selected = $(e.target);
    let data = this.selected.data();
    let name = data.name;
    let type = data.type;

    switch (type) {
      case "circle":
        this.circleTool.highlight(name);
        break;

      case "doubleFixedGate":
        this.doubleFixedGateTool.highlight(name);
        break;

      case "doubleVariableGate":
        this.doubleVariableGateTool.highlight(name);
        break;

      case "fence":
        this.fenceTool.highlight(name);
        break;

      case "arrow":
        this.arrowTool.highlight(name);
        break;

      case "rectangle":
        this.rectangleTool.highlight(name);
        break;

      case "singleFixedGate":
        this.singleFixedGateTool.highlight(name);
        break;

      case "singleVariableGate":
        this.singleVariableGateTool.highlight(name);
        break;

      case "roll-gate":
        this.singleVariableGateTool.highlight(name);
        break;

      case "v-track-gate":
        this.singleVariableGateTool.highlight(name);
        break;

      case "cantilever-gate":
        this.singleVariableGateTool.highlight(name);
        break;

      case "text":
        this.textTool.highlight(name);
        break;
    }
  }

  create_popover(e) {
    var selected = e.target;

    var handler = function (e) {
      if ($(e.target).hasClass("pop-save")) {
        let name = $(selected).data().name;
        let text = $("#text-input").val();
        canvas_selector.setLayer(name, {
          text: text,
        });
        $(selected).data("friendly_name", `"${text}"`);
        $(selected).data("text", text);
        $(selected).text(`"${text}"`);

        canvas_selector.removeLayerGroup("highlight");
        canvas_selector.drawLayers();
        $(selected).popover("hide");
      } else if ($(e.target).hasClass("pop-delete")) {
      } else if ($(e.target).hasClass("pop-cancel")) {
      }
      //handler();
      return false;
    };

    $(".popover_list").not(selected).popover("hide");
    $(".pop-delete").on("click", this.delegate(this, this.delete));
    $(".pop-cancel").on("click", this.delegate(this, this.cancel));
    $(".pop-save-text").on("click", this.delegate(this, this.saveText));
    $(".pop-save-fence").on("click", this.delegate(this, this.saveFence));
    $(".pop-save-gate").on("click", this.delegate(this, this.saveGate));
    $(".showMeasurements").on(
      "click",
      this.delegate(this, this.toggleShowMeasurements)
    );
  }

  delegate(object, method) {
    console.log(object);
    var shim = function () {
      method.apply(object, arguments);
    };
    return shim;
  }

  close() {
    this.sidebar.removeClass("active");
    this.content.addClass("active");
    $("html").removeClass("fixed");
    $("body").removeClass("fixed");
  }
}
