import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import JobNimbusSettings from './components/JobNimbus';
import SketchSettings from './components/Sketch';

export default function Settings() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  return (
    <div
      id="Settings"
      className="w-full"
    >
      <Sidebar />
      <div style={{ paddingLeft: '208px' }}>
        {query.get('type') === 'integrations' ? <JobNimbusSettings /> : query.get('type') === 'sketch' ? <SketchSettings /> : <></>}
      </div>
    </div>
  );
}
