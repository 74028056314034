import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Switch, useNavigate } from 'react-router-dom';
import { getFirestore, query, getDocs, collection, where, addDoc, getDoc, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from './api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import './index.css';
//import './selectFencing';
// import { AuthProvider } from './Contexts/Auth';
import Sketch from './Pages/Sketch';
import Settings from './Pages/Settings/Settings';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Logout from './Pages/Logout';
import Header from './Components/Header';
import PrivateRoute from './Components/PrivateRoute';
import { useAuthContext, setUser } from './Contexts/AuthContext';

export default function App() {
  const [user, loading, error] = useAuthState(auth);
  const [value, loadingCollection, collectionError] = useCollection(query(collection(db, 'users'), where('uid', '==', user && user.uid)), {});
  const { dispatch } = useAuthContext();

  useEffect(() => {
    if (loading && !user) return;

    if (user && value) {
      dispatch(setUser(value.docs[0].data()));
    }
  }, [loadingCollection]);

  useEffect(() => {
    var r = document.querySelector(':root');
    console.log(require('./config.json').primary);
    r.style.setProperty('--color-header', require('./config.json').primary);
  }, []);

  return (
    <div>
      <Router>
        {/* <ReactNotifications /> */}
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Header logo={require('./config.json').logo} />
                <Sketch />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Header logo={require('./config.json').logo} />
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            exact
            path="/register"
            element={<Register />}
          />
          {/* <Route
            path='/reset-password'
            element={<ResetPassword />}
          /> */}
          <Route
            path="/logout"
            element={<Logout />}
          />
        </Routes>
      </Router>
    </div>
  );
}

const delay = (ms) => {
  //console.log('sleeping')
  return new Promise((res) => setTimeout(res, ms));
};
