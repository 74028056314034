export class UndoTool {
  constructor(canvas) {
    this.stack = [];
    this.stackPosition = 0;
    this.canvas = canvas;
  }

  set stack(stack) {
    this._stack = stack;
  }
  get stack() {
    return this._stack;
  }

  set stackPosition(stackPosition) {
    this._stackPosition = stackPosition;
  }
  get stackPosition() {
    return this._stackPosition;
  }

  set canvas(canvas) {
    this._canvas = canvas;
  }
  get canvas() {
    return this._canvas;
  }

  activate(options) {
    this.canvas.setLayers({ draggable: false });
  }
  close() {
    return true;
  }
  onMouseDown(position) {
    return true;
  }
  onMouseUp(position) {
    return true;
  }
  onMouseLeave(position) {
    return true;
  }
  onClick(position) {
    return true;
  }
  onMouseMove(position) {
    return true;
  }

  pushToStack(functions) {
    this.stack.splice(this.stackPosition, this.stack.length, functions);
    this.stackPosition++;
  }

  undo() {
    console.log(this.stackPosition);
    if (this.stackPosition > 0) {
      this.stackPosition--;
      this.stack[this.stackPosition].undo();
    }
  }

  redo() {
    if (this.stackPosition < this.stack.length) {
      this.stack[this.stackPosition].redo();
      this.stackPosition++;
    }
  }
}
