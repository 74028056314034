import { Tool } from "./Tool.js";
import { GridTool } from "./GridTool.js";
/*
GATE CLASS
This class handles drawing a gate over an existing fence segment
*/
export class SingleFixedGateTool extends Tool {
  constructor(canvas, preview_canvas, options) {
    super(canvas, preview_canvas, options);

    this.fenceTool = options.fenceTool;
    this.previewTextColor = options.previewTextColor || "rgba(0, 0, 0, 1)";
    this.previewFenceColor =
      options.previewFenceColor || "rgba(255, 165, 165, 1)";
    this.previewGatePointColor =
      options.previewGatePointColor || "rgba(0, 76, 0, 1)";
    this.previewGateColor = options.previewGateColor || "rgba(0, 76, 0, 1)";
    this.fixedWidth = options.fixedWidth;
    this.intersected_line = null;
    this.lastPosition = null;
  }

  activate(options) {
    super.activate(options);

    this.previewTextColor = options.previewTextColor || "rgba(0, 0, 0, 1)";
    this.previewFenceColor =
      options.previewFenceColor || "rgba(255, 165, 165, 1)";
    this.previewGatePointColor =
      options.previewGatePointColor || "rgba(0, 76, 0, 1)";
    this.previewGateColor = options.previewGateColor || "rgba(0, 76, 0, 1)";
    this.fixedWidth = options.fixedWidth;
    this.lastPreviewPosition = { x: 0, y: 0 };
    this.createEventListeners();
    this.createPreviewWatcher();
  }

  createHandlers() {
    var tool = this;
    var getPosition = this.options.getPosition;

    this.clickHandler = (function () {
      return function (e) {
        e.preventDefault();
        let position = getPosition(e);
        if (tool.drawing) {
          tool.createFixedWidthGate(position);
          $.event.trigger({
            type: "switchTool",
            tool: "selector",
            color_override: null,
          });
        } else {
          tool.createNewLine(position);
        }
        return true;
      };
    })();

    this.moveHandler = (function () {
      return function (e) {
        e.preventDefault();
        let position = getPosition(e);
        tool.previewPosition = position;
        return true;
      };
    })();
  }

  createPreviewWatcher() {
    this.previewWatcher = requestAnimationFrame(
      this.delegate(this, this.previewTick)
    );
  }

  destroyPreviewWatcher() {
    cancelAnimationFrame(this.previewWatcher);
  }

  previewTick(timestamp) {
    if (!this.active) {
      return false;
    }
    let currentPosition = this.previewPosition;
    let lastPosition = this.lastPreviewPosition;
    if (currentPosition == undefined || lastPosition == undefined) {
      //do nothing
    } else if (timestamp < this.lastPreviewTimestamp + 50) {
      //do nothing
    } else if (currentPosition != lastPosition) {
      this.preview(currentPosition);
    }
    this.createPreviewWatcher();
  }

  preview(position) {
    this.lastPreviewTimestamp = performance.now();
    this.lastPreviewPosition = position;
    let preview_started = !(
      this.preview_canvas.getLayerGroup("preview") == undefined
    );

    //get closest intersect point
    let closest = this.getClosestLineToPoint(position);
    let line = closest.line;
    let intersect = closest.intersect;

    //re-set the visible property for all layers
    this.canvas.setLayers({ visible: true }, (layer) => {
      try {
        return !(
          layer.data.drawParams.options.drawMeasurements === false &&
          layer.type === "text"
        );
      } catch (err) {
        return true;
      }
    });

    //set the visibility of the best line to false so we can see the preview
    this.canvas.setLayerGroup(line.name, { visible: false });
    this.hiddenLayers = line.name;

    this.preview_canvas.removeLayerGroup("preview");

    let preview_gate = this.drawGate("preview", intersect, line, {
      strokeStyle: this.previewGatePointColor,
      preview: true,
      update: preview_started,
      drawMeasurements: false,
    });
    let preview_gate_start = { x: preview_gate.x1, y: preview_gate.y1 };
    let preview_gate_end = { x: preview_gate.x2, y: preview_gate.y2 };

    let fence_a_start = { x: line.x1, y: line.y1 };
    let fence_b_end = { x: line.x2, y: line.y2 };

    let gate_ordered_points =
      this.getDistanceBetweenPoints(fence_a_start, preview_gate_start) <
      this.getDistanceBetweenPoints(fence_a_start, preview_gate_end)
        ? { start: preview_gate_start, end: preview_gate_end }
        : { start: preview_gate_end, end: preview_gate_start };

    this.fenceTool.drawFence(
      "preview-a",
      fence_a_start,
      gate_ordered_points.start,
      {
        strokeStyle: this.previewFenceColor,
        groups: ["preview"],
        preview: true,
        update: preview_started,
        drawMeasurements: false,
      }
    );

    this.fenceTool.drawFence(
      "preview-b",
      gate_ordered_points.end,
      fence_b_end,
      {
        strokeStyle: this.previewFenceColor,
        groups: ["preview"],
        preview: true,
        update: preview_started,
        drawMeasurements: false,
      }
    );

    this.draw(this.preview_canvas);
    return true;
  }

  createEventListeners() {
    if (!this.listening) {
      this.listening = true;
      this.canvas[0].addEventListener("touchstart", this.clickHandler, false);
      this.canvas[0].addEventListener("touchend", this.clickHandler, false);
      this.canvas[0].addEventListener("touchmove", this.moveHandler, false);
      this.canvas[0].addEventListener("mouseup", this.clickHandler, false);
      this.canvas[0].addEventListener("mousedown", this.clickHandler, false);
      this.canvas[0].addEventListener("mousemove", this.moveHandler, false);
    }
    return true;
  }

  destroyEventListeners() {
    if (this.listening) {
      this.listening = false;
      this.canvas[0].removeEventListener(
        "touchstart",
        this.clickHandler,
        false
      );
      this.canvas[0].removeEventListener("touchend", this.clickHandler, false);
      this.canvas[0].removeEventListener("touchmove", this.moveHandler, false);
      this.canvas[0].removeEventListener("mouseup", this.clickHandler, false);
      this.canvas[0].removeEventListener("mousedown", this.clickHandler, false);
      this.canvas[0].removeEventListener("mousemove", this.moveHandler, false);
    }
    return true;
  }

  close() {
    super.close();
    this.destroyEventListeners();
    this.destroyPreviewWatcher();
    this.getFences();
  }

  createNewLine(position) {
    let closest = this.getClosestLineToPoint(position);

    this.drawing = true;
    this.intersected_line = closest.line;
    this.startPosition = closest.intersect;
  }

  createFixedWidthGate(position) {
    let closest = this.getClosestLineToPoint(position);
    let line = closest.line;
    let intersect = closest.intersect;

    //draw the gate
    let gate_name = this.uuid();
    let opts = this.options;
    opts.strokeStyle =
      this.options.strokeStyle || line.data.drawParams.options.strokeStyle;

    console.log(this.options.rotation);
    opts.friendly_name = this.getId();

    let new_gate = this.createGate(gate_name, intersect, line, opts);
    let new_gate_start = { x: new_gate.x1, y: new_gate.y1 };
    let new_gate_end = { x: new_gate.x2, y: new_gate.y2 };

    let fence_a_start = { x: line.x1, y: line.y1 };
    let fence_b_end = { x: line.x2, y: line.y2 };

    let gate_ordered_points =
      this.getDistanceBetweenPoints(fence_a_start, new_gate_start) <
      this.getDistanceBetweenPoints(fence_a_start, new_gate_end)
        ? { start: new_gate_start, end: new_gate_end }
        : { start: new_gate_end, end: new_gate_start };

    let fenceOpts = line.data.drawParams.options;
    fenceOpts.update = false;

    let fence_a_name = this.uuid();
    let fence_a = this.fenceTool.createFence(
      fence_a_name,
      fence_a_start,
      gate_ordered_points.start,
      fenceOpts
    );

    let fence_b_name = this.uuid();
    fenceOpts.friendly_name = this.fenceTool.getId();
    let fence_b = this.fenceTool.createFence(
      fence_b_name,
      gate_ordered_points.end,
      fence_b_end,
      fenceOpts
    );

    //remove the original line and text
    this.editTool.remove_object(line);
    this.canvas.removeLayerGroup(line.name);
  }

  createGate(name, intersect, intersected_line, options) {
    this.activate(options);
    let gate = this.drawGate(name, intersect, intersected_line, options);
    this.editTool.add_object(gate);
    this.close();
    return gate;
  }

  rotate(rotation) {
    this.options.rotation = rotation;
  }

  getFences() {
    return this.canvas.getLayers(function (layer) {
      return (
        layer.groups.indexOf("fences") > -1 && layer.data.type != "tear-out"
      );
    });
  }

  drawGate(name, intersect, intersected_line, options) {
    //console.log(options);
    let rotation = this.options.rotation || 1;
    if (options.edit) {
      rotation = options.rotation || 1;
    }
    //console.log(`drawing gate with rotation of: ${rotation}`);
    let strokeStyle = options.strokeStyle || "rgba(0, 76, 0, 1)";
    let strokeWidth = options.strokeWidth || 4;
    let groups = [name, "gates", options.type];
    let dragGroups = name == "preview" ? null : options.dragGroups || [name];
    let px_width = this.fixedWidth * this.gridTool.pt_per_ft;
    let canvas = groups.hasAttribute(["preview"])
      ? this.preview_canvas
      : this.canvas;
    let update =
      (options.update && canvas.getLayer(name) != undefined) || false;
    let friendly_name = options.friendly_name || "Gate";

    let dx = intersected_line.x2 - intersected_line.x1;
    let dy = intersected_line.y2 - intersected_line.y1;
    let intersected_line_slope = dy / dx;
    let intersected_line_slope2 =
      intersected_line_slope * intersected_line_slope;
    let intersected_line_inverse_slope = -1 / intersected_line_slope;
    let intersected_line_inverse_slope2 =
      intersected_line_inverse_slope * intersected_line_inverse_slope;
    let slope_type = Math.abs(dy) > Math.abs(dx) ? Infinity : 0;

    let gate = {};
    let gate_edge = {};
    let ccw = false;
    let angle = 0;
    let end_angle = 90;
    let text_position_type;

    if (rotation == 1) {
      if (Math.abs(intersected_line_slope) == Infinity) {
        let dir = intersected_line.y1 < intersected_line.y2;
        let bound = dir
          ? { x: intersected_line.x1, y: intersected_line.y1 }
          : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x, y: bound.y + px_width };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y - px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x - px_width,
          y2: intersect.y,
        };
        ccw = false;
        angle = 270;
        end_angle = 360;
        text_position_type = "normal";
      } else if (intersected_line_slope == 0) {
        let dir = intersected_line.x1 < intersected_line.x2;
        let bound = dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x - px_width, y: bound.y };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x + px_width,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y - px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        ccw = false;
        angle = 0;
        end_angle = 90;
        text_position_type = "inverse";
      } else if (slope_type == Infinity) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let bound =
          intersected_line.y1 < intersected_line.y2
            ? { x: intersected_line.x1, y: intersected_line.y1 }
            : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
          y2:
            intersect.y +
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width * Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
          y2:
            intersect.y -
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
        };
        ccw = true;
        angle = this.getAngleOfLine(
          { x: gate.x1, y: gate.y1 },
          { x: gate.x2, y: gate.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "inverse";
      } else if (slope_type == 0) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let dir =
          Math.abs(intersected_line_slope) == intersected_line_slope
            ? intersected_line.y1 < intersected_line.y2
            : intersected_line.y1 > intersected_line.y2;
        let bound = dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
          y2:
            intersect.y +
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)),
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
          y2:
            intersect.y -
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
        };
        ccw = false;
        angle = this.getAngleOfLine(
          { x: gate_edge.x1, y: gate_edge.y1 },
          { x: gate_edge.x2, y: gate_edge.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "inverse";
      }
    } else if (rotation == 2) {
      if (Math.abs(intersected_line_slope) == Infinity) {
        let dir = intersected_line.y1 < intersected_line.y2;
        let bound = dir
          ? { x: intersected_line.x1, y: intersected_line.y1 }
          : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x, y: bound.y + px_width };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y - px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x + px_width,
          y2: intersect.y,
        };
        ccw = false;
        angle = 0;
        end_angle = 90;
        text_position_type = "inverse";
      } else if (intersected_line_slope == 0) {
        let dir = intersected_line.x1 < intersected_line.x2;
        let bound = dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x - px_width, y: bound.y };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x + px_width,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y + px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        ccw = false;
        angle = 90;
        end_angle = 180;
        text_position_type = "normal";
      } else if (slope_type == Infinity) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let bound =
          intersected_line.y1 < intersected_line.y2
            ? { x: intersected_line.x1, y: intersected_line.y1 }
            : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
          y2:
            intersect.y +
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width * Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
          y2:
            intersect.y +
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
        };
        ccw = false;
        angle = this.getAngleOfLine(
          { x: gate.x1, y: gate.y1 },
          { x: gate.x2, y: gate.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "normal";
      } else if (slope_type == 0) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let dir =
          Math.abs(intersected_line_slope) == intersected_line_slope
            ? intersected_line.y1 < intersected_line.y2
            : intersected_line.y1 > intersected_line.y2;
        let bound = dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
          y2:
            intersect.y +
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)),
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
          y2:
            intersect.y +
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
        };
        ccw = false;
        angle = this.getAngleOfLine(
          { x: gate.x1, y: gate.y1 },
          { x: gate.x2, y: gate.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "normal";
      }
    } else if (rotation == 3) {
      if (Math.abs(intersected_line_slope) == Infinity) {
        let dir = intersected_line.y1 < intersected_line.y2;
        let bound = !dir
          ? { x: intersected_line.x1, y: intersected_line.y1 }
          : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x, y: bound.y - px_width };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y + px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x + px_width,
          y2: intersect.y,
        };
        ccw = false;
        angle = 90;
        end_angle = 180;
        text_position_type = "normal";
      } else if (intersected_line_slope == 0) {
        let dir = intersected_line.x1 < intersected_line.x2;
        let bound = !dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x + px_width, y: bound.y };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x - px_width,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y + px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        ccw = false;
        angle = 180;
        end_angle = 270;
        text_position_type = "normal";
      } else if (slope_type == Infinity) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let bound =
          intersected_line.y1 > intersected_line.y2
            ? { x: intersected_line.x1, y: intersected_line.y1 }
            : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
          y2:
            intersect.y -
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width * Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
          y2:
            intersect.y +
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
        };
        ccw = false;
        angle = this.getAngleOfLine(
          { x: gate_edge.x1, y: gate_edge.y1 },
          { x: gate_edge.x2, y: gate_edge.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "normal";
      } else if (slope_type == 0) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let dir =
          intersected_line_slope > 0
            ? intersected_line.y1 < intersected_line.y2
            : intersected_line.y1 > intersected_line.y2;
        let bound = !dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
          y2:
            intersect.y -
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)),
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x +
            px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
          y2:
            intersect.y +
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
        };
        ccw = false;
        angle = this.getAngleOfLine(
          { x: gate_edge.x1, y: gate_edge.y1 },
          { x: gate_edge.x2, y: gate_edge.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "normal";
      }
    } else if (rotation == 4) {
      if (Math.abs(intersected_line_slope) == Infinity) {
        let dir = intersected_line.y1 < intersected_line.y2;
        let bound = !dir
          ? { x: intersected_line.x1, y: intersected_line.y1 }
          : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x, y: bound.y - px_width };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y + px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x - px_width,
          y2: intersect.y,
        };
        ccw = false;
        angle = 180;
        end_angle = 270;
        text_position_type = "inverse";
      } else if (intersected_line_slope == 0) {
        let dir = intersected_line.x1 < intersected_line.x2;
        let bound = !dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect = { x: bound.x + px_width, y: bound.y };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2: intersect.x - px_width,
          y2: intersect.y,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y - px_width,
          x2: intersect.x,
          y2: intersect.y,
        };
        ccw = false;
        angle = 270;
        end_angle = 360;
        text_position_type = "inverse";
      } else if (slope_type == Infinity) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let bound =
          intersected_line.y1 > intersected_line.y2
            ? { x: intersected_line.x1, y: intersected_line.y1 }
            : { x: intersected_line.x2, y: intersected_line.y2 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x -
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y -
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
          y2:
            intersect.y -
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)) *
              slope_sign,
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width * Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
          y2:
            intersect.y -
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)),
        };
        ccw = true;
        angle = this.getAngleOfLine(
          { x: gate_edge.x1, y: gate_edge.y1 },
          { x: gate_edge.x2, y: gate_edge.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "inverse";
      } else if (slope_type == 0) {
        let slope_sign = intersected_line_slope > 0 ? -1 : 1;
        let dir =
          Math.abs(intersected_line_slope) == intersected_line_slope
            ? intersected_line.y1 < intersected_line.y2
            : intersected_line.y1 > intersected_line.y2;
        let bound = !dir
          ? { x: intersected_line.x2, y: intersected_line.y2 }
          : { x: intersected_line.x1, y: intersected_line.y1 };

        if (this.getDistanceBetweenPoints(bound, intersect) < px_width) {
          intersect =
            intersected_line_slope > 0
              ? {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                }
              : {
                  x:
                    bound.x +
                    px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
                  y:
                    bound.y +
                    intersected_line_slope *
                      px_width *
                      Math.sqrt(1 / (1 + intersected_line_slope2)),
                };
        }

        gate = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width * Math.sqrt(1 / (1 + intersected_line_slope2)),
          y2:
            intersect.y -
            intersected_line_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_slope2)),
        };
        gate_edge = {
          x1: intersect.x,
          y1: intersect.y,
          x2:
            intersect.x -
            px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
          y2:
            intersect.y -
            intersected_line_inverse_slope *
              px_width *
              Math.sqrt(1 / (1 + intersected_line_inverse_slope2)) *
              slope_sign,
        };
        ccw = true;
        angle = this.getAngleOfLine(
          { x: gate_edge.x1, y: gate_edge.y1 },
          { x: gate_edge.x2, y: gate_edge.y2 }
        );
        end_angle = ccw ? angle - 90 : angle + 90;
        text_position_type = "inverse";
      }
    }

    let metadata = this.gridTool.getLineData(
      { x: gate.x1, y: gate.y1 },
      { x: gate.x2, y: gate.y2 }
    );

    let x1;
    let x2;
    let y1;
    let y2;

    if (update) {
      let old_line = canvas.getLayer(name);
      let old_x1 = old_line.x1;
      let old_y1 = old_line.y1;
      let old_x2 = old_line.x2;
      let old_y2 = old_line.y2;

      x1 = "+=" + (gate.x1 - old_x1);
      y1 = "+=" + (gate.y1 - old_y1);
      x2 = "+=" + (gate.x2 - old_x2);
      y2 = "+=" + (gate.y2 - old_y2);
    } else {
      x1 = gate.x1;
      y1 = gate.y1;
      x2 = gate.x2;
      y2 = gate.y2;
    }

    let text_position = {};
    if (text_position_type == "inverse") {
      text_position.x = metadata.inverse_text_pos_x;
      text_position.y = metadata.inverse_text_pos_y;
    } else {
      text_position.x = metadata.text_pos_x;
      text_position.y = metadata.text_pos_y;
    }

    let gate_obj = {
      name: name,
      type: "line",
      strokeStyle: strokeStyle,
      strokeWidth: strokeWidth,
      index: -1,
      x1: x1,
      y1: y1,
      x2: x2,
      y2: y2,
      groups: groups,
      dragGroups: dragGroups,
      draggable: false,
      data: {
        length: metadata.length,
        length_ft: metadata.length_ft,
        length_in: metadata.length_in,
        slope: metadata.slope,
        text_pos_x: text_position.x,
        text_pos_y: text_position.y,
        gridTool: this.gridTool,
        friendly_name: friendly_name,
      },
    };

    gate_obj.data.drawParams = {
      name: name,
      intersect: intersect,
      intersected_line: intersected_line,
      options: options,
      type: "singleFixedGate",
    };

    let swing = {
      name: name + "-swing",
      type: "path",
      strokeStyle: strokeStyle,
      strokeWidth: strokeWidth,
      layer: true,
      groups: [gate_obj.name, gate_obj.name + "-swing"],
      dragGroups: dragGroups,
      draggable: false,
      p1: {
        type: "arc",
        x: intersect.x,
        y: intersect.y,
        radius: metadata.length,
        start: angle,
        end: end_angle,
        ccw: ccw,
      },
      p2: {
        type: "line",
        x1: gate_edge.x1,
        y1: gate_edge.y1,
        x2: gate_edge.x2,
        y2: gate_edge.y2,
      },
      data: {},
    };

    if (update) {
      canvas.setLayer(name, gate_obj);
      canvas.setLayer(name + "-swing", swing);
      canvas.removeLayerGroup(gate_obj.name + "-text");
    } else {
      canvas.addLayer(gate_obj);
      canvas.addLayer(swing);
    }
    let layer = canvas.getLayer(name);

    if (options.drawMeasurements) {
      this.addText({
        x: gate_obj.data.text_pos_x,
        y: gate_obj.data.text_pos_y,
        anchor: { x: metadata.text_anchor_x, y: metadata.text_anchor_y },
        text: gate_obj.data.length_ft + "' " + gate_obj.data.length_in + "''",
        groups: [gate_obj.name, gate_obj.name + "-text"],
        dragGroups: [gate_obj.name],
        draggable: false,
        preview: options.preview || false,
        angle: metadata.angle,
        visible: options.drawMeasurements,
      });
    }

    return layer;
  }

  getClosestLineToPoint(point) {
    //loop over all of the fences to find the best distance, intersect, and line
    let set_of_fences = [];
    if (this.drawing) {
      set_of_fences = [this.intersected_line];
    } else {
      set_of_fences = this.getFences();
    }

    let best_distance = null;
    let best_intersect = null;
    let best_line = null;
    for (var i = 0; i < set_of_fences.length; i++) {
      let dist = this.distToSegment(
        point,
        { x: set_of_fences[i].x1, y: set_of_fences[i].y1 },
        { x: set_of_fences[i].x2, y: set_of_fences[i].y2 }
      );
      let distance = dist.distance;
      let intersect = dist.intersect;

      if (distance < best_distance || best_distance == null) {
        best_distance = distance;
        best_intersect = intersect;
        best_line = set_of_fences[i];
      }
    }

    let data_clean = {
      product_id: best_line.data.product_id,
      drawParams: best_line.data.drawParams,
    };

    return {
      line: {
        x1: best_line.x1,
        y1: best_line.y1,
        x2: best_line.x2,
        y2: best_line.y2,
        name: best_line.name,
        data: data_clean,
      },
      intersect: best_intersect,
      distance: best_distance,
    };
  }

  highlight(name) {
    let layer = this.canvas.getLayer(name);
    this.canvas.removeLayerGroup("highlight");
    let line = {
      name: "highlight",
      type: "line",
      strokeStyle: "rgba(0,0,0,1)",
      strokeWidth: 4,
      shadowBlur: 8,
      shadowColor: "rgba(243,243,21,1)",
      index: 999,
      x1: layer.x1,
      y1: layer.y1,
      x2: layer.x2,
      y2: layer.y2,
      groups: ["highlight"],
      draggable: false,
    };
    this.canvas.addLayer(line);
    this.draw(this.canvas);
  }
}
