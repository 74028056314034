import { Tool } from "./Tool.js";

export class CircleTool extends Tool {
	constructor(canvas, preview_canvas, options){
		super(canvas, preview_canvas, options);
		
		this.startPosition = null;
	}

	activate(options){
		super.activate(options);
		this.createEventListeners();
	}

	createHandlers(){
		var tool = this;
		var getPosition = this.options.getPosition;
		
		this.clickHandler = (function(){
			return function(e){
				e.preventDefault();
				let position = getPosition(e);
				if (tool.drawing) {
					tool.completeExistingCircle(position);
				} else {
					tool.createNewCircle(position);
				}
				return true;
			}
		})();

		this.moveHandler = (function(){
			return function(e){
				e.preventDefault();
				let position = getPosition(e);
				tool.previewPosition = position;
				return true;
			}
		})();
	}

	createPreviewWatcher(){
		this.previewWatcher = requestAnimationFrame(this.delegate(this, this.previewTick));
	}

	destroyPreviewWatcher(){
		cancelAnimationFrame(this.previewWatcher);
	}

	previewTick(timestamp){
		let currentPosition = this.previewPosition;
		let lastPosition = this.lastPreviewPosition;
		if (currentPosition == undefined || lastPosition == undefined) {
			//do nothing
		} else if (timestamp < this.lastPreviewTimestamp + 50){
			//do nothing
		} else if (currentPosition != lastPosition){
			this.preview(currentPosition);
		}
		this.createPreviewWatcher();
	}

	preview(position){
		this.lastPreviewTimestamp = performance.now();
		this.lastPreviewPosition = position;
		let preview_started = !(this.preview_canvas.getLayerGroup('preview') == undefined);

		let radius = this.getDistanceBetweenPoints(this.startPosition, position);
		this.drawCircle('preview', this.startPosition, radius, {
			preview: true,
			update: preview_started,
			strokeStyle: 'rgba(255, 127, 63, .75)',
			fillStyle: 'rgba(191, 127, 63, .5)',
		});

		this.draw(this.preview_canvas);
	}

	createEventListeners(){
		this.canvas[0].addEventListener('touchstart',this.clickHandler,false);
		this.canvas[0].addEventListener('touchend',this.clickHandler,false);
    	this.canvas[0].addEventListener('touchmove',this.moveHandler,false);
    	this.canvas[0].addEventListener('mouseup',this.clickHandler,false);
    	this.canvas[0].addEventListener('mousemove',this.moveHandler,false);
		return true;
	}

	destroyEventListeners(){
		this.canvas[0].removeEventListener('touchstart',this.clickHandler,false);
		this.canvas[0].removeEventListener('touchend',this.clickHandler,false);
    	this.canvas[0].removeEventListener('touchmove',this.moveHandler,false);
    	this.canvas[0].removeEventListener('mouseup',this.clickHandler,false);
    	this.canvas[0].removeEventListener('mousemove',this.moveHandler,false);
		return true;
	}

	close(){
		super.close();
		this.destroyEventListeners();
	}

	createNewCircle(position){
		this.drawing = true;
		this.startPosition = position;
		this.lastPreviewPosition = { x: 0, y: 0 };
		this.createPreviewWatcher();
	}

	completeExistingCircle(position){
		this.destroyPreviewWatcher();
		let opts = this.options;
		opts.friendly_name = this.getId();
		let radius = this.getDistanceBetweenPoints(this.startPosition, position);
		this.createCircle(this.uuid(), this.startPosition, radius, opts);
		this.activate(this.options);
	}

	createCircle(name, position, radius, options){
		let circle = this.drawCircle(name, position, radius, options);
		this.editTool.add_object(circle);
		this.close();
	}

	drawCircle(name, position, radius, options){
		let strokeStyle = options.strokeStyle || 'rgba(255, 127, 63, 1)';
		let strokeWidth = options.strokeWidth || 4;
		let fillStyle = options.fillStyle || 'rgba(191, 127, 63, 1)'
		let groups = [name, 'circles', options.type];
		let dragGroups = null;
		dragGroups = (name == 'preview') ? null : (options.dragGroups || [name]);
		let update = options.update || false;
		let canvas = (groups.hasAttribute(['preview'])) ? this.preview_canvas : this.canvas;
		let friendly_name = options.friendly_name || 'Tree';

		let x = position.x;
		let y = position.y;
		let r = radius;

		if (update) {
			let old = canvas.getLayer(name);
			let old_r = old.radius;
			r = '+='+(r - old_r);
		} else {
			if (r < 0) {
				r = -r;
			}
		}

		let circle = {
			name: name,
			type: 'arc',
			strokeStyle: strokeStyle,
			strokeWidth: strokeWidth,
			fillStyle: fillStyle,
			index: -1,
			x: x,
			y: y,
			radius: r,
			groups: groups,
			draggable: false,
			data: {
				friendly_name: friendly_name
			}
		}

		circle.data.drawParams = {
			name: name,
			position: position,
			radius: radius,
			options: options,
			type: 'circle'
		};

		if (update) {
			canvas.setLayer(name, circle);
		} else {
			canvas.addLayer(circle);
		}

		return circle;
	}

	highlight(name){
		let layer = this.canvas.getLayer(name);
		this.canvas.removeLayerGroup('highlight');
		let circle = {
			name: 'highlight',
			type: 'arc',
			strokeStyle: 'rgba(0,0,0,1)',
			strokeWidth: 4,
			shadowBlur: 8,
			shadowColor: 'rgba(243,243,21,1)',
			index: 999,
			x: layer.x,
			y: layer.y,
			radius: layer.radius,
			groups: ['highlight'],
			draggable: false
		};
		this.canvas.addLayer(circle);
		this.draw(this.canvas);
	}
}