import { Tool } from './Tool.js';
import { GridTool } from './GridTool.js';

export class RectangleTool extends Tool {
  constructor(canvas, preview_canvas, options) {
    super(canvas, preview_canvas, options);

    this.resizeAt = null;
    this.resizeLastPosition = null;
    this.resizeLayerOriginalValues = null;
    this.lastPosition = null;
    this.startPosition = null;
  }

  activate(options) {
    super.activate(options);

    this.createEventListeners();
  }

  createHandlers() {
    var tool = this;
    var getPosition = this.options.getPosition;

    this.clickHandler = (function () {
      return function (e) {
        e.preventDefault();
        let position = getPosition(e);
        if (tool.drawing) {
          tool.completeExistingRectangle(position);
          $.event.trigger({
            type: 'switchTool',
            tool: 'selector',
            color_override: null
          });
        } else {
          tool.createNewRectangle(position);
        }
        return true;
      };
    })();

    this.moveHandler = (function () {
      return function (e) {
        e.preventDefault();
        let position = getPosition(e);
        tool.previewPosition = position;
        return true;
      };
    })();
  }

  createPreviewWatcher() {
    this.previewWatcher = requestAnimationFrame(this.delegate(this, this.previewTick));
  }

  destroyPreviewWatcher() {
    cancelAnimationFrame(this.previewWatcher);
  }

  previewTick(timestamp) {
    if (!this.active) {
      return false;
    }
    let currentPosition = this.previewPosition;
    let lastPosition = this.lastPreviewPosition;
    if (currentPosition == undefined || lastPosition == undefined) {
      //do nothing
    } else if (timestamp < this.lastPreviewTimestamp + 50) {
      //do nothing
    } else if (currentPosition != lastPosition) {
      this.preview(currentPosition);
    }
    this.createPreviewWatcher();
  }

  preview(position) {
    this.lastPreviewTimestamp = performance.now();
    this.lastPreviewPosition = position;
    let preview_started = !(this.preview_canvas.getLayerGroup('preview') == undefined);

    let height = position.y - this.startPosition.y;
    let width = position.x - this.startPosition.x;
    this.drawRectangle('preview', this.startPosition, height, width, {
      preview: true,
      strokeStyle: 'rgba(255, 127, 63, .75)',
      fillStyle: 'rgba(191, 127, 63, .5)',
      update: preview_started
    });

    this.draw(this.preview_canvas);
  }

  createEventListeners() {
    this.canvas[0].addEventListener('touchstart', this.clickHandler, false);
    this.canvas[0].addEventListener('touchend', this.clickHandler, false);
    this.canvas[0].addEventListener('touchmove', this.moveHandler, false);
    this.canvas[0].addEventListener('mouseup', this.clickHandler, false);
    this.canvas[0].addEventListener('mousemove', this.moveHandler, false);
    return true;
  }

  destroyEventListeners() {
    this.canvas[0].removeEventListener('touchstart', this.clickHandler, false);
    this.canvas[0].removeEventListener('touchend', this.clickHandler, false);
    this.canvas[0].removeEventListener('touchmove', this.moveHandler, false);
    this.canvas[0].removeEventListener('mouseup', this.clickHandler, false);
    this.canvas[0].removeEventListener('mousemove', this.moveHandler, false);
    return true;
  }

  close() {
    super.close();
    this.destroyPreviewWatcher();
    this.destroyEventListeners();
  }

  createNewRectangle(position) {
    this.drawing = true;
    this.startPosition = position;
    this.lastPreviewPosition = { x: 0, y: 0 };
    this.createPreviewWatcher();
  }

  completeExistingRectangle(position) {
    let rect_name = this.uuid();
    let height = position.y - this.startPosition.y;
    let width = position.x - this.startPosition.x;
    let opts = this.options;
    opts.friendly_name = this.getId();
    this.createRectangle(rect_name, this.startPosition, height, width, opts);
    this.activate(this.options);
  }

  createRectangle(name, position, height, width, options) {
    let rectangle = this.drawRectangle(name, position, height, width, options);
    this.editTool.add_object(rectangle);
    this.close();
  }

  drawRectangle(name, position, height, width, options) {
    let strokeStyle = options.strokeStyle || 'rgba(255, 127, 63, 1)';
    let strokeWidth = options.strokeWidth || 4;
    let fillStyle = options.fillStyle || 'rgba(191, 127, 63, 1)';
    let groups = [name, 'rectangles', options.type];
    let dragGroups = null;
    dragGroups = name == 'preview' ? null : options.dragGroups || [name];
    let metadata_w = this.gridTool.getLineData(position, { x: position.x + width, y: position.y });
    let metadata_h = this.gridTool.getLineData(position, { x: position.x, y: position.y + height });
    let canvas = groups.hasAttribute(['preview']) ? this.preview_canvas : this.canvas;
    let update = (options.update && canvas.getLayer(name) != undefined) || false;
    let friendly_name = options.friendly_name || 'House';

    let x = position.x;
    let y = position.y;
    let h = height;
    let w = width;

    if (update) {
      let old = canvas.getLayer(name);
      let old_x = old.x;
      let old_y = old.y;
      let old_w = old.width;
      let old_h = old.height;

      x = '+=' + (position.x - old_x);
      y = '+=' + (position.y - old_y);
      w = '+=' + (width - old_w);
      h = '+=' + (height - old_h);
    } else {
      if (h < 0) {
        h = -h;
        y = y - h;
      }

      if (w < 0) {
        w = -w;
        x = x - w;
      }
    }

    let rectangle = {
      name: name,
      type: 'rectangle',
      strokeStyle: strokeStyle,
      strokeWidth: strokeWidth,
      fillStyle: fillStyle,
      fromCenter: false,
      index: -1,
      x: x,
      y: y,
      height: h,
      width: w,
      groups: groups,
      dragGroups: dragGroups,
      draggable: false,
      handle: {
        type: 'arc',
        fillStyle: '#fff',
        strokeStyle: '#c33',
        strokeWidth: 2,
        radius: 5
      },
      handlestart: this.delegate(this, this.resizeStart),
      handlemove: this.delegate(this, this.resize),
      handlestop: this.delegate(this, this.resizeEnd),
      data: {
        height: metadata_h.length,
        height_ft: metadata_h.length_ft,
        height_in: metadata_h.length_in,
        height_text_pos_x: metadata_h.text_pos_x,
        height_text_pos_y: metadata_h.text_pos_y,

        width: metadata_w.length,
        width_ft: metadata_w.length_ft,
        width_in: metadata_w.length_in,
        width_text_pos_x: metadata_w.text_pos_x,
        width_text_pos_y: metadata_w.text_pos_y,
        options: options,
        friendly_name: friendly_name
      }
    };

    rectangle.data.drawParams = {
      name: name,
      position: position,
      height: height,
      width: width,
      options: options,
      type: 'rectangle'
    };

    if (update) {
      canvas.setLayer(name, rectangle);
      canvas.removeLayerGroup(rectangle.name + '-width');
      canvas.removeLayerGroup(rectangle.name + '-height');
    } else {
      canvas.addLayer(rectangle);
    }

    return rectangle;
  }

  highlight(name) {
    let layer = this.canvas.getLayer(name);
    this.canvas.removeLayerGroup('highlight');
    let rect = {
      name: 'highlight',
      type: 'rectangle',
      strokeStyle: 'rgba(0,0,0,1)',
      strokeWidth: 4,
      shadowBlur: 8,
      shadowColor: 'rgba(243,243,21,1)',
      index: 999,
      fromCenter: false,
      x: layer.x,
      y: layer.y,
      height: layer.height,
      width: layer.width,
      groups: ['highlight'],
      draggable: false
    };
    this.canvas.addLayer(rect);
    this.draw(this.canvas);
  }

  resizeStart(layer) {
    if (this.drawing) {
      return false;
    }
    this.resizeLayerOriginalValues = {
      name: layer.name,
      start: { x: layer.x, y: layer.y },
      height: layer.height,
      width: layer.width
    };

    let clickLocation = this.snapPosition({ x: layer.eventX, y: layer.eventY });
    let dist_to_tl = this.getDistanceBetweenPoints(clickLocation, { x: layer.x, y: layer.y });
    let dist_to_tr = this.getDistanceBetweenPoints(clickLocation, { x: layer.x + layer.width, y: layer.y });
    let dist_to_bl = this.getDistanceBetweenPoints(clickLocation, { x: layer.x, y: layer.y + layer.height });
    let dist_to_br = this.getDistanceBetweenPoints(clickLocation, { x: layer.x + layer.width, y: layer.y + layer.height });

    let closest_handle = Math.min(dist_to_tl, dist_to_tr, dist_to_bl, dist_to_br);
    if (closest_handle == dist_to_tl) {
      this.resizeAt = 'tl';
      this.resizeLastPosition = clickLocation;
    } else if (closest_handle == dist_to_tr) {
      this.resizeAt = 'tr';
      this.resizeLastPosition = clickLocation;
    } else if (closest_handle == dist_to_bl) {
      this.resizeAt = 'bl';
      this.resizeLastPosition = clickLocation;
    } else if (closest_handle == dist_to_br) {
      this.resizeAt = 'br';
      this.resizeLastPosition = clickLocation;
    }
  }

  resize(layer) {
    if (this.drawing) {
      return false;
    }
    let new_point = this.snapPosition({ x: layer.eventX, y: layer.eventY });
    let updateNeeded = this.resizeLastPosition.x !== new_point.x || this.resizeLastPosition.y !== new_point.y;
    let pos_start = null;
    let h = layer.height;
    let w = layer.width;

    let dx = this.resizeLastPosition.x - new_point.x;
    let dy = this.resizeLastPosition.y - new_point.y;

    if (updateNeeded) {
      this.resizeLastPosition = new_point;

      if (this.resizeAt == 'tl') {
        pos_start = new_point;
        h = layer.height + dy;
        w = layer.width + dx;
      } else if (this.resizeAt == 'tr') {
        pos_start = { x: layer.x, y: new_point.y };
        h = layer.height + dy;
        w = layer.width - dx;
      } else if (this.resizeAt == 'bl') {
        pos_start = { x: new_point.x, y: layer.y };
        h = layer.height - dy;
        w = layer.width + dx;
      } else if (this.resizeAt == 'br') {
        pos_start = { x: layer.x, y: layer.y };
        h = layer.height - dy;
        w = layer.width - dx;
      }

      let opts = Object.assign({}, layer.data.options, { update: true });
      this.drawRectangle(layer.name, pos_start, h, w, opts);

      this.draw(this.canvas);
    }
    return true;
  }

  resizeEnd(layer) {
    if (this.drawing) {
      return false;
    }

    this.resizeAt = null;
    this.draw(this.canvas);
  }
}
