import React from "react";

export default function SettingsModal() {
  return (
    <div
      class='modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto'
      id='settings-modal'
      tabindex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div class='modal-dialog relative w-auto pointer-events-none'>
        <div class='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
          <div class='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
            <h5
              class='text-xl font-medium leading-normal text-gray-800'
              id='exampleModalLabel'
            >
              Settings
            </h5>
            <button
              type='button'
              class='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
              data-bs-dismiss='modal'
              data-dismiss='modal'
            />
          </div>
          <div class='modal-body relative p-4'>
            <div class='modal-body'>
              <div className='mb-1'>
                <h2 className='font-medium'>Font Size</h2>
                <p className='text-xs my-2 text-onPrimaryBgSofter'>
                  Change the size of the text
                </p>
                <div class='flex w-full'>
                  <div class='mb-3 w-full'>
                    <select
                      id='settingsFontSize'
                      class='form-select 
                      appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value='10'>10</option>
                      <option value='12'>12</option>
                      <option value='14'>14</option>
                      <option value='16'>16</option>
                      <option value='18'>18</option>
                      <option value='20'>20</option>
                      <option value='22'>22</option>
                      <option value='24'>24</option>
                      <option
                        value='26'
                        selected
                      >
                        26
                      </option>
                      <option value='28'>28</option>
                      <option value='30'>30</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='mb-1'>
                <h2 className='font-medium'>Scale</h2>
                <p className='text-xs my-2 text-onPrimaryBgSofter'>
                  Default grid scale is 6px / ft. Scale can only be changed
                  before elements are drawn on the canvas. Changing the scale
                  after drawing an object will cause unexpected results.
                </p>
                <div class='flex w-full'>
                  <div class='mb-3 w-full'>
                    <select
                      id='settingsScale'
                      class='form-select 
                      appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value='1'>1px / ft</option>
                      <option value='3'>3px / ft</option>
                      <option
                        value='6'
                        selected
                      >
                        6px / ft
                      </option>
                      <option value='9'>9px / ft</option>
                      <option value='12'>12px / ft</option>
                      <option value='15'>15px / ft</option>
                      <option value='18'>18px / ft</option>
                      <option value='21'>21px / ft</option>
                      <option value='24'>24px / ft</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='mb-1'>
                <h2 className='font-medium'>Grid Size</h2>
                <p className='text-xs my-2 text-onPrimaryBgSofter'>
                  Larger grid sizes may cause performance decreases on mobile
                  devices. For very large grid sizes consider using a desktop
                  rather than a tablet.
                </p>
                <div class='flex w-full'>
                  <div class='mb-3 w-full'>
                    <select
                      id='settingsGridSize'
                      class='form-select 
                      appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value='1024'>85'</option>
                      <option value='1536'>128'</option>
                      <option
                        value='2048'
                        selected
                      >
                        170'
                      </option>
                      <option value='3072'>256'</option>
                      <option value='4096'>340'</option>
                      <option value='6144'>512'</option>
                      <option value='8192'>680'</option>
                      <option value='12288'>1019'</option>
                      <option value='16384'>1365'</option>
                      <option value='24576'>2048'</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='mb-1'>
                <h2 className='font-medium'>Snap to nearest</h2>
                <p className='text-xs my-2 text-onPrimaryBgSofter'>
                  Change the snap-to-grid behavior between nearest foot or
                  nearest inch. Only affects newly drawn objects
                </p>
                <div class='flex w-full'>
                  <div class='w-full'>
                    <select
                      id='settingsSnapUnit'
                      class='form-select 
                      appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option
                        value='6'
                        selected
                      >
                        foot
                      </option>
                      <option value='1'>inch</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md'>
            <button
              type='button'
              class={`px-6
          py-2.5
          bg-gray-500
          text-white
          font-medium
          text-xs
          leading-tight
          uppercase
          rounded
          shadow-md
         
          transition
          duration-150
          ease-in-out`}
              data-bs-dismiss='modal'
              data-dismiss='modal'
            >
              Close
            </button>
            <button
              id='save-settings'
              type='button'
              class={`px-6
          py-2.5
          !bg-header
          text-white
          font-medium
          text-xs
          leading-tight
          uppercase
          rounded
          shadow-md
         
          transition
          duration-150
          ease-in-out`}
              data-bs-dismiss='modal'
              data-dismiss='modal'
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// {
//   <div
//     id='settings-modal'
//     class='modal'
//     tabindex='-1'
//     role='dialog'
//   >
//     <div
//       class='modal-dialog'
//       role='document'
//     >
//       <div class='modal-content'>
//         <div class='modal-header'>
//           <h5 class='modal-title'>Settings</h5>
//           <button
//             type='button'
//             class='close'
//             data-dismiss='modal'
//             aria-label='Close'
//           >
//             <span aria-hidden='true'>&times;</span>
//           </button>
//         </div>

//         <div class='modal-footer'>
//           <button
//             id='save-settings'
//             type='button'
//             class='btn btn-primary !bg-header'
//           >
//             Save changes
//           </button>
//         </div>
//       </div>
//     </div>
//   </div>;
// }
