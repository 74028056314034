import React, { useState, useEffect } from "react";

export default function Toolbar() {
  const [scale, setScale] = useState(1.0);
  const min = 0.25;
  const max = 1.25;
  const step = 0.05;

  return (
    // <div className={`${!show && 'hidden'} bg-white h-20 rounded-md w-full`}>
    <div className={`!z-[1001] fixed bottom-10 right-5 transform`}>
      <div class='flex flex-row'>
        <button
          onClick={() => {
            let num = Number(parseFloat(scale - step).toFixed(2));
            setScale(num);
            document.getElementById("zoom-input").value = num;
            document.getElementById("zoom-input").dispatchEvent(
              new Event("change", {
                value: document.getElementById("zoom-input").value,
              })
            );
          }}
          class={`w-9 h-9 min-w-min
            rounded-none rounded-l-md text-lg
            bg-white shadow-md
            
            hover:!bg-[${require("../config.json").primary}] hover:bg-opacity-50
            outline-none hover:shadow-md
            transition duration-100 ease-in-out
            mb-0 border
            border-gray-300 border-opacity-30
            hover:!bg-[${
              require("../config.json").primary
            }] disabled:cursor-not-allowed disabled:shadow-none disabled:hover:bg-gray-300 disabled:hover:bg-opacity-30`}
          title='Zoom In'
          disabled={scale <= min}
        >
          <i class='mdi mdi-minus'></i>
        </button>

        <div
          class='bg-white shadow-md
            border border-b border-gray-300 border-opacity-30
            font-medium py-2 cursor-default
            w-9 h-9 min-w-min
            text-[12px] text-center
            inline-block align-baseline'
        >
          <input
            onChange={() => console.log(scale)}
            id='zoom-input'
            className='hidden'
            value={scale}
            type='range'
            min='.25'
            max='1.25'
            step='0.05'
            defaultValue={1}
          />
          {Math.round(scale * 100)}%
        </div>

        <button
          onClick={() => {
            let num = Number(parseFloat(scale + step).toFixed(2));
            setScale(num);
            document.getElementById("zoom-input").value = num;
            document.getElementById("zoom-input").dispatchEvent(
              new Event("change", {
                value: document.getElementById("zoom-input").value,
              })
            );
          }}
          class={`w-9 h-9 min-w-min
            rounded-none rounded-r-md text-lg
            bg-white shadow-md
   
            hover:!bg-[${require("../config.json").primary}] hover:bg-opacity-50
            outline-none hover:shadow-md
            transition duration-100 ease-in-out
            mb-0 border
            border-gray-300 border-opacity-30
            hover:!bg-[${
              require("../config.json").primary
            }] disabled:cursor-not-allowed disabled:shadow-none disabled:hover:bg-gray-300 disabled:hover:bg-opacity-30`}
          title='Zoom Out'
          disabled={scale >= max}
        >
          <i class='mdi mdi-plus'></i>
        </button>
      </div>
    </div>
  );
}
